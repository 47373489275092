import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";

const dictionaryAdapter = createEntityAdapter({
  selectId: (dictionary) => dictionary.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState: dictionaryAdapter.getInitialState(),
  reducers: {
    add: dictionaryAdapter.addOne,
    setAll: dictionaryAdapter.setAll,
    update: dictionaryAdapter.upsertOne,
    removeOne: dictionaryAdapter.removeOne,
    empty: dictionaryAdapter.removeAll,
  },
});

export const { actions } = dictionarySlice;

export const selectors = dictionaryAdapter.getSelectors(
  (state) => state.dictionary
);
