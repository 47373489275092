import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";

const programAdapter = createEntityAdapter({
  selectId: (program) => program.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const programSlice = createSlice({
  name: "program",
  initialState: programAdapter.getInitialState(),
  reducers: {
    add: programAdapter.addOne,
    setAll: programAdapter.setAll,
    update: programAdapter.upsertOne,
    removeOne: programAdapter.removeOne,
    empty: programAdapter.removeAll,
  },
});

export const { actions } = programSlice;

export const selectors = programAdapter.getSelectors((state) => state.program);
