import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const labelAdapter = createEntityAdapter({
  selectId: (label) => label.id
});

export const labelSlice = createSlice({
  name: "label",
  initialState: labelAdapter.getInitialState(),
  reducers: {
    add: labelAdapter.addOne,
    setAll: labelAdapter.setAll,
    update: labelAdapter.upsertOne,
    removeOne: labelAdapter.removeOne,
    empty: labelAdapter.removeAll,
  },
});

export const { actions } = labelSlice;

export const selectors = labelAdapter.getSelectors((state) => state.label);