import { useState, useEffect } from "react";
import { Button, Flex } from "@chakra-ui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { InputText } from 'primereact/inputtext';
import { locale, addLocale } from 'primereact/api';
import { v4 as uuidv4 } from "uuid";
import { AddIcon } from "@chakra-ui/icons";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

const StageList = (props) => {
  const { data, setData } = props;
  const [stages, setStages] = useState([]);

  useEffect(() => {
    setStages(data);
  },[data] );

  useEffect(() => {
    if (stages && ( Array.isArray(stages)) && (stages.length > 0)) {
      setData(stages);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stages])

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  locale('es');

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const startBodyTemplate = (rowData) => {
    return ( typeof rowData.start === 'string') 
      ? rowData.start : rowData.start.toISOString().split('T')[0];
  } 

  const endBodyTemplate = (rowData) => {
    return ( typeof rowData.end === 'string') 
      ? rowData.end : rowData.end.toISOString().split('T')[0];
  } 

  const dateEditor = (props, field, format) => {
    return (
      <>
        <Calendar
          baseZIndex={9999}
          value={new Date(props.rowData[field])}
          onChange={(e) => props.editorCallback(e.target.value)}
          dateFormat={format}
        />
      </>
    );
  }

  const onRowEditComplete = (e) => {
    let _stages = [...stages];
    let { newData, index } = e;
    const _newData = {
      stage_id: newData.stage_id, 
      stage_name: newData.stage_name, 
      start: newData.start,
      end: newData.end,
    }
    _stages[index] = _newData;
    setStages(_stages);
  }

  const onNewRow = () => {
    const ahora = new Date(Date.now());
    const newData = {
      stage_id: uuidv4(),
      stage_name: `Etapa${stages.length+1}`,
      start: ahora,
      end: ahora,
    }
    setStages([...stages, newData]);
  }

  return (
      <Flex direction="column">
        <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
          <Button
            mb="6"
            rightIcon={<AddIcon />}
            colorScheme="teal"
            onClick={onNewRow}
            ml="auto">
            Nuevo
          </Button>
        </Flex>
        <DataTable 
          id="tableStages"
          //showGridlines 
          value={stages} 
          editMode="row" 
          dataKey="stage_id" 
          onRowEditComplete={onRowEditComplete} 
          responsiveLayout="scroll" 
        >
          <Column 
            field="stage_name" 
            header="Nombre de la etapa" 
            editor={(options) => textEditor(options)} 
            style={{ width: "30%"}}
          />
          <Column 
            field="start" 
            header="Inicio"
            body={startBodyTemplate}
            editor={(props) => dateEditor(props, "start", "yy-mm-dd")} 
            style={{ width: "30%"}}
          />
          <Column 
            field="end" 
            header="Final" 
            body={endBodyTemplate}
            editor={(props) => dateEditor(props, "end", "yy-mm-dd")} 
            style={{ width: "30%"}}
          />
          <Column 
            rowEditor 
            header="Acciones" 
            headerStyle={{ width: '10%', minWidth: '8rem' }} 
            bodyStyle={{ textAlign: 'center' }}
          />
        </DataTable>
      </Flex>
    
  );
}

export default StageList;