import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";

const policyTypeAdapter = createEntityAdapter({
  selectId: (policyType) => policyType.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const policyTypeSlice = createSlice({
  name: "policyType",
  initialState: policyTypeAdapter.getInitialState(),
  reducers: {
    add: policyTypeAdapter.addOne,
    setAll: policyTypeAdapter.setAll,
    update: policyTypeAdapter.upsertOne,
    removeOne: policyTypeAdapter.removeOne,
    empty: policyTypeAdapter.removeAll,
  },
});

export const { actions } = policyTypeSlice;

export const selectors = policyTypeAdapter.getSelectors((state) => state.policyType);
