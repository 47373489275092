import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";
import { set } from "lodash";

const propertyAdapter = createEntityAdapter({
  selectId: (property) => property.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const propertySlice = createSlice({
  name: "property",
  initialState: propertyAdapter.getInitialState(),
  reducers: {
    add: propertyAdapter.addOne,
    setAll: propertyAdapter.setAll,
    update: propertyAdapter.upsertOne,
    removeOne: propertyAdapter.removeOne,
    empty: propertyAdapter.removeAll,
    updatePropertyLocationProps(state, action){
        const {id, location_prop} = action.payload;
        const element = JSON.parse(JSON.stringify(state.entities));
        const propty = element[id];
        if (propty) {
            propty.location_props = {...propty.location_props, ...location_prop };
            propertyAdapter.upsertOne(state, propty);
            return;
        }
    },
    updatePropertyJson(state, action){
        const {id, key, value} = action.payload;
        const element = JSON.parse(JSON.stringify(state.entities));
        const ppty = element[id];
        if (ppty) {
            let keys = key.split('__')
            let setter_key = `${keys[0]}`
            if(keys.length > 1){
                keys?.slice(1, keys.length).map((k)=>{
                    setter_key = setter_key + `.${k}`
                })
            }
            const custom_value = set(ppty, setter_key, value);
            propertyAdapter.upsertOne(state, custom_value);
            return;
        }
    }
  },
});

export const { actions } = propertySlice;

export const selectors = propertyAdapter.getSelectors((state) => state.property);