import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const developmentArchAdapter = createEntityAdapter({
  selectId: (devArch) => devArch.id,
});

export const developmentArchSlice = createSlice({
  name: "developmentArch",
  initialState: developmentArchAdapter.getInitialState(),
  reducers: {
    add: developmentArchAdapter.addOne,
    setAll: developmentArchAdapter.setAll,
    update: developmentArchAdapter.upsertOne,
    removeOne: developmentArchAdapter.removeOne,
    empty: developmentArchAdapter.removeAll,
    updatePropertyJson(state, action){
      const {id, key, value} = action.payload;
      const element = JSON.parse(JSON.stringify(state.entities));
      const ppty = element[id];
      if (ppty) {
          let keys = key.split('__')
          let setter_key = `${keys[0]}`
          if(keys.length > 1){
              keys?.slice(1, keys.length).map((k)=>{
                  setter_key = setter_key + `.${k}`
              })
          }
          const custom_value = set(ppty, setter_key, value);
          developmentArchAdapter.upsertOne(state, custom_value);
          return;
      }
    }
  }
});

export const { actions } = developmentArchSlice;

export const selectors = developmentArchAdapter.getSelectors((state) => state.developmentArch);