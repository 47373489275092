import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { boomerangApi } from "./boomerangApi";
import { userSlice } from "./userSlice";
import { companySlice } from "./companySlice";
import { programSlice } from "./programSlice";
import { projectSlice } from "./projectSlice";
import { zoneSlice } from "./zoneSlice";
import { contactSlice } from "./contactSlice";
import { dictionarySlice } from "./dictionarySlice";
import { draftSlice } from "./genericSlice";
import { dataTypeSlice } from "./dataTypeSlice";
import { controlTypeSlice } from "./controlTypeSlice";
import { company_departmentSlice } from "./company_departmentSlice";
import { labelSlice } from "./labelSlice";
import { propertySlice } from "./propertySlice";
import { property_featureSlice } from "./propertyFeaturesSlice";
import { property_mediaSlice } from "./propertyMediaSlice";
import { developmentSlice } from "./developmentSlice";
import { developmentArchSlice } from "./developmentArchSlice";
import { policyTypeSlice } from "./policyTypeSlice";
import { developmentFinSlice } from "./developmentFinSlice";
import { localStorageSlice } from './localStorageSlice';
import { priceListSlice } from "./priceListSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['boomerangApi'],
  whitelist: ['localStorageSlice'],
}
export const rootReducers = combineReducers({
  localStorage: localStorageSlice,
  [boomerangApi.reducerPath]: boomerangApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

const store = configureStore({
  reducer: {
    persistedReducer,
    draft: draftSlice.reducer,
    user: userSlice.reducer,
    company: companySlice.reducer,
    company_department: company_departmentSlice.reducer,
    program: programSlice.reducer,
    zone: zoneSlice.reducer,
    dictionary: dictionarySlice.reducer,
    project: projectSlice.reducer,
    dataType: dataTypeSlice.reducer,
    controlType: controlTypeSlice.reducer,
    label: labelSlice.reducer,
    localStorage: localStorageSlice.reducer,
    contact: contactSlice.reducer,
    property: propertySlice.reducer,
    property_feature: property_featureSlice.reducer,
    property_media: property_mediaSlice.reducer,
    [boomerangApi.reducerPath]: boomerangApi.reducer,
    development: developmentSlice.reducer,
    developmentArch: developmentArchSlice.reducer,
    policyType: policyTypeSlice.reducer,
    developmentFin: developmentFinSlice.reducer,
    priceList: priceListSlice.reducer,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([boomerangApi.middleware]),
});

// store.subscribe(() => {
//   localStorage.setItem("reduxState", JSON.stringify(store.getState()));
// });

setupListeners(store.dispatch)
export default store;
