import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const zoneAdapter = createEntityAdapter({
  selectId: (zone) => zone.id
});

export const zoneSlice = createSlice({
  name: "zone",
  initialState: zoneAdapter.getInitialState(),
  reducers: {
    add: zoneAdapter.addOne,
    setAll: zoneAdapter.setAll,
    /*
    setAll(state, action) {
      // reorganizar las zonas para mover aquellas que tengan un parent
      // al correspondiente

      // función recursiva para buscar donde debe ir una zona e insertarla
      function findItIn(zoneArray, zone_id, zoneToInsert ) {
        let result = false;
        if (zoneArray && (zoneArray.length > 0)) {
          zoneArray.forEach((item, idx) => {
            // si es el que busco, lo inserto
            if (item.id === zone_id) {
              // si aun no tiene un array de children zones, lo creo
              if (!item.zone) {
                zoneArray[idx].zone = [];
              }
              if (!zoneArray[idx].zone.find(el => el.id === zoneToInsert.id)) {
                zoneArray[idx].zone.push(zoneToInsert);
              }
              result = true;
            } else {
              // si no es el que busco, veo si children para que lo busque en esos
              if (item.zone && (item.zone.length > 0)){
                result = findItIn(item.zone, zone_id, zoneToInsert );
              }
            }
          });
        }
        return result;
      }

      const zonas = cloneDeep( action.payload );
      const removed = [];
      // barre las zonas
      zonas.forEach(zona => {
        // si tiene parent lo mando a reubicar
        if (zona.zone_id && (!removed.includes(zona.id))) {
          // buscar en donde debe ir e insertarlo
          const found = findItIn(zonas, zona.zone_id, zona );

          // si se logra encontrar, entonces lo borro
          if (found) {
            removed.push(zona.id);
          }
        }
      });
      remove( zonas, item => removed.includes(item.id));
      zoneAdapter.setAll( state, zonas );
    },
*/
    update: zoneAdapter.upsertOne,
    removeOne: zoneAdapter.removeOne,
    empty: zoneAdapter.removeAll,
  },
});

export const { actions } = zoneSlice;

export const selectors = zoneAdapter.getSelectors((state) => state.zone);