import { useRef, useState, useEffect } from "react";
import { Box, IconButton, Button, Flex, Center, Spinner } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  useGetEntityQuery,
  useGetFormsByCategoryQuery,
  usePostEntityMutation,
} from "../../store/boomerangApi";
import { SchemaForm, SchemaTable } from "@grupo-guia/boomerang-schema-ui";
import { EditIcon, SmallAddIcon } from "@chakra-ui/icons";
import { get } from "lodash";
import { actions, selectors } from "../../store/policyTypeSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const RowStatus = (props) => {
  // console.log(props);
  return (
    <Box
      fontWeight={800}
      px="2"
      rounded="full"
      textAlign="center"
      color="white"
      py="1"
      bg={props.dataColumn === "ACTIVO" ? "green.500" : "red.500"}
      fontSize="sm">
      {props.dataColumn}
    </Box>
  );
};

const codeCategoryForm = "TECH-PLP-POL";
const entity = "policy_type";

const PolicyTypes = () => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [editId, setEditId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [tableInfo, setTableInfo] = useState({});
  const [schemaProperties, setSchemaProperties] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { data, isLoading } = useGetEntityQuery({ entity }); // fetch entity array
  // fetch ui & entity with redux toolkit query
  const { data: dataForms } = useGetFormsByCategoryQuery({
    code: codeCategoryForm,
  });
  const [postEntity] = usePostEntityMutation();
  const draftData = useSelector((state) => selectors.selectById(state, editId));
  const dataPolicies = useSelector(selectors.selectAll);

  // modal handlers
  const onNew = () => {
    // dispatch(actions.empty());
    const newData = { id: uuidv4() };
    dispatch(actions.add({ ...newData }));
    setEditId(newData.id);
    onOpen();
  };

  const onSave = async (e) => {
    e.preventDefault();
    try {
      console.log(draftData);
      const response = await postEntity({ entity, ...draftData });
      dispatch(actions.update(response.data));
      setEditId(null);
    } catch (error) {
      console.log("error:", error);
    }
    onClose();
  };

  useEffect(() => {
    if (dataForms) {
      const [objTable, objSchema] = dataForms;
      setTableInfo(objTable || {});
      setSchemaProperties(objSchema || {});
    }
  }, [dataForms]);

  useEffect(() => {
    if (tableInfo?.properties) {
      const fieldsToShow = get(tableInfo, "properties.fieldsToShow", []);
      const fieldLabels = get(tableInfo, "properties.fieldLabels", []);
      const mapped = fieldsToShow.map((field, i) => {
        return { key: field, text: fieldLabels[i] || field };
      });
      setColumns(mapped.concat([{ key: "actions", text: "Acciones" }]));
    }
  }, [tableInfo, tableInfo?.properties]);

  // when dataEntity changes
  /*
  useEffect(() => {
    if (editId) {
      const tmp = data.find((item) => item.id === editId);
      if (tmp) {
        dispatch(actions.add(tmp));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);
  */

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      dispatch(actions.setAll(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const HeaderTable = (props) => {
    return (
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          rightIcon={<SmallAddIcon />}
          colorScheme="teal"
          onClick={onNew}
          ml="auto">
          Nuevo
        </Button>
      </Flex>
    );
  };

  const EditComponent = ({ dataRow }) => {
    return (
      <IconButton
        colorScheme="blue"
        aria-label="Editar fila"
        icon={<EditIcon />}
        onClick={() => {
          setEditId(dataRow.id);
          onOpen();
        }}
      />
    );
  };

  const policyTypeFormHandler = (key, inputValue) => {
    console.log(key, inputValue);
    dispatch(actions.update({ id: editId, [key]: inputValue }));
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        size="3xl"
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nueva politica</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={onSave}>
            <ModalBody pb={6}>
              <SchemaForm
                schema={schemaProperties}
                customModelSetter={policyTypeFormHandler}
                customModelValues={editId ? draftData : {}}
                classNames={{
                  templateColumns: { md: "repeat(2, 1fr)", base: "1fr" },
                  gap: { md: 3, base: 2 },
                  mt: "2",
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Box max="6xl" mx="auto" mt="2" pt={0} pb={0}>
        {isLoading === true && (
          <Center h='100px'>
            <Spinner color='yellow.400' size='lg' />
          </Center>
        )}
        {isLoading === false && (
          <Box bg="white" p="2">
            {tableInfo.properties ?
              <SchemaTable
                tabTitle="Politicas de precio"
                headerTable={HeaderTable}
                title="Politicas de precio"
                columns={columns}
                data={editId ? data : dataPolicies}
                Rowstatus={RowStatus}
                Rowactions={EditComponent}
                tabkey="name"
              />
            :
              <Box>
                <Center h='100px'>
                  <Spinner color='yellow.400' size='xl' />
                </Center> 
              </Box>
            }
          </Box>
        )}
      </Box>
    </>
  );
};

export default PolicyTypes;
