import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Input, Radio, RadioGroup, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Button as ButtonPrime } from "primereact/button";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { find } from "lodash";
import { classNames } from "primereact/utils";
import { addLocale, locale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";

const PolicyList = (props) => {
  const { data, setData, price_lists } = props;
  const [policies, setPolicies] = useState(data || []);
  const types = [
    { value:'LINEALXVOLUMEN', label: "Lineal x Volumen" }, 
    { value:'LINEALXTIEMPO', label: "Lineal x Tiempo" },
    { value:'ESCALONADOXVOLUMEN', label: "Escalonado x Volumen" }, 
    { value: "ESCALONADOXTIEMPO", label: "Escalonado x Tiempo" },
  ];
  const statuses = ["ACTIVE", "INACTIVE"];
  const periods = [
    { value: "days", label: "Días" },
    { value: "months", label: "Meses" },
  ];
  const units = [{ values: "units", label: "Unidades" }];
  const increaseTypes = [
    { value: "percentage", label: "Porcentaje" },
    { value: "amount", label: "Monto" },
  ]
  const [selected, setSelected] = useState(null);

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  locale('es');

  /*
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setPolicies(data.policy_props || []);
    }
  }, [data]);
  */
  /*
  useEffect(() => {
    if (price_lists) {
      console.log('price_list:', price_lists);
    }
  }, [price_lists]);
  */
  useEffect(() => {
    if (Array.isArray(policies) && policies.length > 0) {
      setData(policies);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies]);

  const onNewRow = () => {
    const newData = {
      id: uuidv4(),
      name: `Política ${policies.length+1}`,
      status: 'ACTIVE',
      type: 'LINEALXVOLUMEN',
      operation_props: {},
    }
    setPolicies([...policies, newData]);
  }

  const onNewIntervalRow = (index) => {
    const policy = policies[index];
    const newData = {
      id: uuidv4(),
      sort_order: (policy.operation_props?.intervals?.length || 0 ) + 1,
      interval_amount: 1,
      interval_type: policy.type === "ESCALONADOXTIEMPO" ? 'months' : 'units',
      increase_amount: 1,
      increase_type: 'percentage',
      applied: null,
    }
    const intervals = policy.operation_props?.intervals || [];
    const operation_props = { ...policy.operation_props, intervals: [...intervals, newData] };
    let _policies = [...policies];
    _policies[index] = { ...policy, operation_props: { ...operation_props} };
    //console.log('_policies:', _policies);
    setPolicies(_policies);
  }

  const onIntervalRowEditComplete = (idx, e) => {
    const policy = policies[idx];
    let { newData, index } = e;
    const intervals = policy.operation_props?.intervals?.map((o,i) => i === index ? newData : o);
    const operation_props = { ...policy.operation_props, intervals };
    let _policies = [...policies];
    _policies[idx] = { ...policy, operation_props: { ...operation_props} };
    setPolicies(_policies);
  }

  const onDeleteIntervalRow = (policyIndex, intervalIndex) => {
    const policy = policies[policyIndex];
    const intervals = policy.operation_props?.intervals?.filter((o,i) => i !== intervalIndex);
    const operation_props = { ...policy.operation_props, intervals };
    let _policies = [...policies];
    _policies[policyIndex] = { ...policy, operation_props: { ...operation_props} };
    setPolicies(_policies);
  }

  const onRowEditComplete = (e) => {
    let _policies = [...policies];
    let { newData, index } = e;
    const _newData = {
      id: newData.id, 
      name: newData.name, 
      status: newData.status,
      type: newData.type,
      operation_props: newData.operation_props,
    }
    _policies[index] = _newData;
    //console.log('DimList.setDimensions2:', _dimensions);
    setPolicies(_policies);
  }  

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const typesEditor = (options) => {
    return (
      <Dropdown
        //zIndex={1}
        value={options.value}
        options={types}
        optionLabel="label"
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Seleccionar Status"
      />
    );
  };  

  const intervalEditor = (options) => {
    return (
      <Input type="number" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} htmlSize={4} width='100px' size='lg' />
    );
  }

  const increaseEditor = (options) => {
    return (
      <Input type="number" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} htmlSize={4} width='100px' size='lg' />
    );
  }

  const intervalTypeEditor = (options, policyType) => {
    return (
      <Dropdown
        value={options.value}
        options={ policyType === 'ESCALONADOXTIEMPO' ? periods : units}
        optionLabel="label"
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  }

  const increaseTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={increaseTypes}
        optionLabel="label"
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  }

  const appliedEditor = (options) => {
    return (
      <>
        { /* }
        <Input
        lang="es"
        value={ options.value ? new Date(options.value) : new Date()}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Select Date and Time"
        size="lg"
        type="date"
       />
       { */}
        <Calendar
          showButtonBar
          baseZIndex={9999}
          value={ new Date(options.value) }
          onChange={(e) => options.editorCallback(e.value)}
          dateFormat={"yy-mm-dd"}
        />
      </>
    );    
  }

  const typesBodyTemplate = (rowData) => {
    //return <Tag value={rowData.inventoryStatus} severity={getSeverity(rowData.inventoryStatus)}></Tag>;
    return <div>{ find(types, { value: rowData.type}).label }</div>;
  };  

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={rowData.status === 'ACTIVE' ? "success" : "danger"}></Tag>;
    //return <div>{rowData.status}</div>;
  };

  const intervalBodyTemplate = (rowData) => {
    return <Text align="center">{rowData.interval_amount}</Text>;
  }

  const increaseBodyTemplate = (rowData) => {
    return <Text align="center">{ rowData.increase_amount }{ rowData.increase_type === 'percentage' ? '%' : 'MXN'}</Text>;
  }

  const intervalTypeBodyTemplate = (rowData, policyType) => {
    //console.log('policyType:', policyType);
    return <div>{ 
      find(
        policyType === 'ESCALONADOXTIEMPO' ? periods : units, 
        { value: rowData.interval_type}
      )?.label || 'Unidades'
    }</div>;
  }

  const increaseTypeBodyTemplate = (rowData) => {
    return <div>{ find(increaseTypes, { value: rowData.increase_type})?.label }</div>;
  }

  const appliedBodyTemplate = (rowData) => {
    return ( typeof rowData.applied === 'string') 
    ? rowData.applied || 'No' : rowData.applied?.toISOString().split('T')[0];
  }

  const actionsBodyTemplate = (policyIndex, rowData, props ) => {
    const rowEditor = props.rowEditor;
    if ( rowEditor.editing ) {
      return rowEditor.element;
    } else {
      return (
        <>
          <ButtonPrime onClick={rowEditor.onInitClick} className={rowEditor.initClassName} key={`edit-${rowData.id}`}>
            <span className='p-row-editor-init-icon pi pi-fw pi-pencil p-clickable'></span>
          </ButtonPrime>
          <ButtonPrime 
            type="button"
            onClick={() => onDeleteIntervalRow(policyIndex, props.rowIndex)} 
            className={rowEditor.initClassName} 
            key={`delete-${rowData.id}`}
          >
            <span className='p-row-editor-init-icon pi pi-fw pi-trash p-clickable'></span>
          </ButtonPrime>
        </>
      );
    }
  }

  const onChangePolicyPriceLists = (index, value ) => {
    // establece las listas de precio que aplican a la política
    //console.log('onChangePolicyPriceLists:', value);
    let _policies = [...policies];
    let _operation_props = _policies[index].operation_props || {};
    let _policy = {..._policies[index], operation_props: { ..._operation_props, price_lists: value } };
    _policies[index] = _policy;
    setPolicies(_policies);
  }

  const onChangeLinealIntervalType = (index, value ) => {
    let _policies = [...policies];
    let _operation_props = _policies[index].operation_props || {};
    let _policy = {..._policies[index], operation_props: { ..._operation_props, interval_type: value } };
    _policies[index] = _policy;
    setPolicies(_policies);
  }

  const onChangeLinealIntervalAmount = (index, value ) => {
    let _policies = [...policies];
    let _operation_props = _policies[index].operation_props || {};
    let _policy = {..._policies[index], operation_props: { ..._operation_props, interval_amount: value } };
    _policies[index] = _policy;
    setPolicies(_policies);
  }

  const onChangeLinealIncreaseType = (index, value ) => {
    let _policies = [...policies];
    let _operation_props = _policies[index].operation_props || {};
    let _policy = {..._policies[index], operation_props: { ..._operation_props, increase_type: value } };
    _policies[index] = _policy;
    setPolicies(_policies);
  }

  const onChangeLinealIncreaseAmount = (index, value ) => {
    let _policies = [...policies];
    let _operation_props = _policies[index].operation_props || {};
    let _policy = {..._policies[index], operation_props: { ..._operation_props, increase_amount: value } };
    _policies[index] = _policy;
    setPolicies(_policies);
  }

  return (
    <Flex direction="column">
      <Tabs variant={"enclosed"}>
        <TabList>
          <Tab>Políticas</Tab>
          { policies.length > 0 && policies.map((policy, index) => (
            <Tab key={policy.id}>{policy.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
              <Button
                mb="6"
                rightIcon={<AddIcon />}
                colorScheme="teal"
                onClick={onNewRow}
                ml="auto">
                Nuevo
              </Button>
            </Flex>
            <DataTable 
              key={`tablePolicies`}
              id={`tablePoliciies`}
              //showGridlines 
              value={policies} 
              editMode="row"
              dataKey="id" 
              onRowEditComplete={onRowEditComplete} 
              responsiveLayout="scroll" 
            >
              <Column
                field="name" 
                header="Nombre" 
                editor={(options) => textEditor(options)} 
                style={{ width: "25%"}}
              />
              <Column
                field="type" 
                header="Tipo" 
                editor={(options) => typesEditor(options)}
                body={typesBodyTemplate}
                style={{ width: "25%"}}
              />
              <Column
                field="status" 
                header="Status" 
                editor={(options) => statusEditor(options)}
                body={statusBodyTemplate}
                style={{ width: "25%"}}
              />
              <Column 
                rowEditor 
                header="Acciones" 
                headerStyle={{ width: '10%', minWidth: '8rem' }} 
                bodyStyle={{ textAlign: 'center' }}
              />
            </DataTable>
          </TabPanel>
          {policies.length > 0 && policies.map((policy, index) => (
            <TabPanel key={policy.id}>
              <Flex direction={"column"} p={4}>
                { /* La selección de listas de precio se hace siempre */ }
                <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                  <Text fontSize="xl" fontWeight="bold">Listas de precio</Text>
                  <RadioGroup onChange={(e) => onChangePolicyPriceLists(index, e)} value={policies[index].operation_props?.price_lists}>
                    <Flex direction="row" justifyContent={"flex-start"}>
                      <Radio value="*" size={"lg"} mr={4}>Todas</Radio>
                      <Radio value={
                          policies[index].operation_props?.price_lists === '*' ? [] : policies[index].operation_props?.price_lists
                        } size={"lg"} mr={4}>Seleccionar</Radio>
                      { policies[index].operation_props?.price_lists !== "*" && (
                        <MultiSelect
                          display="chip"
                          className="w-full md:w-20rem"
                          value={policies[index].operation_props?.price_lists || []} 
                          onChange={(e) => onChangePolicyPriceLists(index, e.value)} 
                          options={price_lists} 
                          optionLabel="name"
                          optionValue="id"
                          placeholder="Seleccionar listas" />
                      )}
                    </Flex>
                  </RadioGroup>
                </Stack>
                { /* Si la politica es lineal se muestra la seleccion de intervalo y monto con sus tipos */ }
                { policies[index].type.includes('LINEAL') && (
                  <>
                  <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Intervalo</Text>
                    <RadioGroup onChange={(e) => onChangeLinealIntervalType(index, e)} value={policies[index].operation_props?.interval_type}>
                      <Flex direction="row" justifyContent={"flex-start"}>
                        <Input 
                          type="number" size={"lg"} width={"10%"} mr={4}
                          value={policies[index].operation_props?.interval_amount} 
                          onChange={(e) => onChangeLinealIntervalAmount(index, e.target.value)} />
                        { policies[index].type.includes("TIEMPO") ? (
                          <>
                            <Radio value="months" size={"lg"} mr={4} >Meses</Radio>
                            <Radio value="days" size={"lg"} mr={4}>Días</Radio>
                          </>
                        ) : (
                          <Radio 
                            value="units" size={"lg"} mr={4}
                            hidden={policies[index].type.includes("TIEMPO")}
                            checked={policies[index].type === "LINEALXVOLUMEN"}
                          >Unidades</Radio>
                        )}
                      </Flex>
                    </RadioGroup>
                  </Stack>
                  <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                    <Text fontSize="xl" fontWeight="bold">Incremento</Text>
                    <RadioGroup onChange={(e) => onChangeLinealIncreaseType(index, e)} value={policies[index].operation_props?.increase_type}>
                      <Flex direction="row" justifyContent={"flex-start"}>
                        <Input 
                          type="number" size={"lg"} width={"20%"} mr={4}
                          value={policies[index].operation_props?.increase_amount} 
                          min={1}
                          max={ policies[index].operation_props?.increase_type === 'percentage' ? 100 : 99999999999}
                          onChange={(e) => onChangeLinealIncreaseAmount(index, e.target.value)} />
                        <Radio value="amount" size={"lg"} mr={4}>Monto</Radio>
                        <Radio value="percentage" size={"lg"} mr={4}>Porcentaje</Radio>
                      </Flex>
                    </RadioGroup>
                  </Stack>
                  </>
                )}
                { /* si la politica es escalonada muestra un grid para capturar los valores */ }
                { policies[index].type.includes('ESCALONADO') && (
                  <>
                  <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
                    <Button
                      mb="6"
                      rightIcon={<AddIcon />}
                      colorScheme="teal"
                      onClick={() => onNewIntervalRow(index)}
                      ml="auto">
                      Nuevo
                    </Button>
                  </Flex>                  
                  <DataTable 
                    key={`tableIntervals-${index}`} 
                    id={`tableIntervals-${index}`} 
                    value={policies[index].operation_props?.intervals || []} 
                    onRowEditComplete={(e) => onIntervalRowEditComplete(index, e)} 
                    editMode="row" 
                    dataKey="id" 
                    sortField="sort_order"
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="sort_order" 
                      header="Orden" 
                      //editor={(options) => textEditor(options)} 
                      style={{ width: "10%"}}
                    />
                    <Column
                      field="interval_amount" 
                      header="Intervalo" 
                      editor={(options) => intervalEditor(options)}
                      body={intervalBodyTemplate}
                      style={{ width: "15%"}}
                    />
                    <Column
                      field="interval_type" 
                      header="Periodo" 
                      editor={(options) => intervalTypeEditor(options, policies[index].type)}
                      body={(options) => intervalTypeBodyTemplate(options, policies[index].type)}
                      style={{ width: "15%"}}
                    />
                    <Column
                      field="increase_amount" 
                      header="Incremento" 
                      editor={(options) => increaseEditor(options)}
                      body={increaseBodyTemplate}
                      style={{ width: "15%"}}
                    />
                    <Column
                      field="increase_type" 
                      header="Tipo" 
                      editor={(options) => increaseTypeEditor(options)}
                      body={increaseTypeBodyTemplate}
                      style={{ width: "15%"}}
                    />
                    <Column
                      field="applied" 
                      header="Aplicado" 
                      editor={(options) => appliedEditor(options)}
                      body={appliedBodyTemplate}
                      style={{ width: "15%"}}
                    />
                    <Column 
                    rowEditor 
                    body={(rowData, props) => actionsBodyTemplate(index,rowData, props)}
                    header="Acciones" 
                    headerStyle={{ width: '10%', minWidth: '8rem' }} 
                    bodyStyle={{ textAlign: 'center' }}
                  />    
                  </DataTable>
                  </>
                )}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default PolicyList;