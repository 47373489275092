import React from 'react';
import lightTheme from "./themes/ligth";
import Router from './root';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider theme={lightTheme}>
      <Router />
    </ChakraProvider>
  );
}

export default App;
