import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";

const projectAdapter = createEntityAdapter({
  selectId: (project) => project.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const projectSlice = createSlice({
  name: "project",
  initialState: projectAdapter.getInitialState(),
  reducers: {
    add: projectAdapter.addOne,
    setAll: projectAdapter.setAll,
    update: projectAdapter.upsertOne,
    removeOne: projectAdapter.removeOne,
    empty: projectAdapter.removeAll,
  },
});

export const { actions } = projectSlice;

export const selectors = projectAdapter.getSelectors((state) => state.project);
