import { CheckboxGroup } from "@chakra-ui/react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useRouteLoaderData } from "react-router-dom";
import { REACT_APP_API_ENDPOINT } from "./apiRoot";

export const loginUser = createAsyncThunk(
  'auth/login',
  async (userKeycloak) => {
    return userKeycloak;
    // try {
    //   const response = await fetch(
    //     `${REACT_APP_API_ENDPOINT}/login`,
    //     {
    //       //mode: 'no-cors',
    //       method: 'POST',
    //       headers: {
    //         Accept: "application/json",
    //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    //       },
    //       body: new URLSearchParams({ email, password }),
    //     }
    //   );
    //   const data = await response.json();
    //   console.log("response", data);
      
    //   if (response.status === 200) {
    //     return data;
    //   } else {
    //     return thunkAPI.rejectWithValue(data);
    //   }
    // } catch (e) {
    //   console.log("Error", e.response.data );
    //   thunkAPI.rejectWithValue(e.response.data);
    // } 
  }
)

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (thunkAPI) => {
    try {
      let header = {};
      const _state = await localStorage.getItem('reduxState');
      const _user = JSON.parse(_state);
      const user = _user.user;
      //console.log('logout info:', user);
      if (user && user.user && user.user.token) {
        header = { "Authorization": `Bearer ${user.user.token}` };
      }
      //console.log("fetch:", header);
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/logout`,
        {
          method: 'POST',
          headers: {
            ...header,
            Accept: "application/json",            
          },
        }
      );
      const data = await response.json();
      //console.log("logout response:", data);
      if (response.status === 200) {
        return data;
      } else {
        console.log("logout reject:", data);
        return thunkAPI.rejectWithValue(data);
      }      
    }
    catch (e) {
      console.log("Error", e.response.data );
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    isFetching: false,
    isSuccess: false,
    isLoggedOut: true,
    isError: false,
    errorMessage: "",
  },

  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
      return state;
    },
    hydrate: (state, action ) => {
      // console.log("hydrate payload:", (action.payload));
      // const payload = { 
      //   user: { 
      //     ...action.payload.user,
      //     token: action.payload.user.token,
      //     refresh_token: action.payload.user.refresh_token,
      //   }, 
      // }
      const payload = {
        user: {
          email: action.payload.email,
          token: action.payload.token,
          refresh_token: action.payload.refreshToken
        }
      }
      return payload;
    },
    eraseState: (state) => {
      state.user = {}
      state.isError = false;
      state.isLoggedOut = true;
      state.isFetching = false;
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
      return state;
    }
  },

  extraReducers: {
    [loginUser.fulfilled]: (state, { payload } ) => {
      console.log('payload sucess:',payload);
      state.user = {
        email: payload.email,
        name: payload.name,
        token: payload.token,
        refresh_token: payload.refresh_token,
      };
      state.isFetching = false;
      state.isSuccess = true;
      state.isLoggedOut = false;
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log('login rejected:', payload);
      state.user = {};
      state.isFetching = false;
      state.isLoggedOut = true;
      state.isError = true;
      state.errorMessage = payload?.message;
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
    },
    [loginUser.pending]: (state) => {
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
      state.isFetching = true;
    },
    [logoutUser.fulfilled]: (state, { payload }) => {
      console.log("logout success:", payload);
      state.user = {};
      state.isFetching = false;
      state.isLoggedOut = true;
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
      return state;
    },
    [logoutUser.rejected]: (state, payload) => {
      console.log('logout rejected:', payload);
      state.user = {};
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
    },
    [logoutUser.pending]: (state) => {
      // Store credentials in local storage
      localStorage.setItem("reduxState", JSON.stringify(state))
      state.isFetching = true;
    },
  },
})
export const { clearState, hydrate, eraseState } = userSlice.actions;
export const userSelector = state => state.user