import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const dataTypeAdapter = createEntityAdapter({
    selectId: (dataType) => dataType.id,
});

export const dataTypeSlice = createSlice({
        name: "data_type",
        initialState: dataTypeAdapter.getInitialState(),
        reducers: {
            add: dataTypeAdapter.addOne,
            setAll: dataTypeAdapter.setAll,
            update: dataTypeAdapter.upsertOne,
            removeOne: dataTypeAdapter.removeOne,
            empty: dataTypeAdapter.removeAll,
        },
    });
  
export const { actions } = dataTypeSlice;
  
export const selectors = dataTypeAdapter.getSelectors((state) => state.dataType);