import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const priceListAdapter = createEntityAdapter({
  selectId: (priceList) => priceList.id,
});

export const priceListSlice = createSlice({
  name: "priceList",
  initialState: priceListAdapter.getInitialState(),
  reducers: {
    add: priceListAdapter.addOne,
    setAll: priceListAdapter.setAll,
    update: priceListAdapter.upsertOne,
    removeOne: priceListAdapter.removeOne,
    empty: priceListAdapter.removeAll,
    updatePropertyJson(state, action){
      const {id, key, value} = action.payload;
      const element = JSON.parse(JSON.stringify(state.entities));
      const ppty = element[id];
      if (ppty) {
          let keys = key.split('__')
          let setter_key = `${keys[0]}`
          if(keys.length > 1){
              keys?.slice(1, keys.length).map((k)=>{
                  setter_key = setter_key + `.${k}`
              });
          }
          const custom_value = set(ppty, setter_key, value);
          priceListAdapter.upsertOne(state, custom_value);
          return;
      }
    },
  },
});

export const { actions } = priceListSlice;

export const selectors = priceListAdapter.getSelectors((state) => state.priceList);