import { Button, Flex, Input, Radio, RadioGroup, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, Tooltip } from "@chakra-ui/react";
import { find } from "lodash";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Button as ButtonPrime } from "primereact/button";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AddIcon } from "@chakra-ui/icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row } from "primereact/row";
import { MultiSelect } from "primereact/multiselect";


const FinancingPlans = (props) => {
  const { data, setData, price_lists } = props;
  const [plans, setPlans] = useState(data || []);
  const statuses = ["ACTIVE", "INACTIVE"];
  const periods = [
    { value: "days", label: "Días" },
    { value: "months", label: "Meses" },
  ];
  const [exhibitionsTotals, setExhibitionsTotals] = useState(new Array(data?.length || 0).fill(0));
  const [percentageTotals, setPercentageTotals] = useState(new Array(data?.length || 0).fill(0));

  useEffect(() => {
    if (Array.isArray(plans) && plans.length > 0) {
      // calcula los totales por cada plan
      const _exhibitionsTotals = [];
      const _percentageTotals = [];
      for (let i = 0; i < plans.length; i++) {
        let exhibitions = 0;
        let percentage = 0;
        for (let j = 0; j < plans[i].payments.length; j++) {
          exhibitions += parseFloat( plans[i].payments[j].exhibitions);
          percentage += parseFloat(plans[i].payments[j].percentage);
        }
        _exhibitionsTotals.push(exhibitions);
        _percentageTotals.push(percentage);
      }
      setExhibitionsTotals(_exhibitionsTotals);
      setPercentageTotals(_percentageTotals);

      setData(plans);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const onNewRow = () => {
    const newData = {
      id: uuidv4(),
      name: `Plan ${plans.length+1}`,
      status: 'ACTIVE',
      description: `Descripción de Plan ${plans.length+1}`,
      discount_percentage: 0,
      payments_total: 0,
      price_lists: "*",
      payments: [],
    }
    setPlans([...plans, newData]);
  }

  const onNewPaymentRow = (index) => {
    const newData = {
      number: plans[index].payments?.length+1,
      exhibitions: 1,
      start: 0,
      start_type: "months",
      interval_type: "months",
      interval_amount: 1,
      percentage: 0,
    }
    const _plans = [...plans];
    const plan = { ..._plans[index], payments: [..._plans[index].payments, newData] };
    //const payments = [...plans[index].payments, newData];
    _plans[index] = plan;
    setPlans(_plans);
  }

  const onRowEditComplete = (e) => {
    let _plans = [...plans];
    let { newData, index } = e;
    const _newData = { ...newData };
    _plans[index] = _newData;
    //console.log('DimList.setDimensions2:', _dimensions);
    setPlans(_plans);
  }

  const onPaymentRowEditComplete = (planIndex, e) => {
    const _plans = [...plans];
    let { newData, index } = e;
    const _payments = [..._plans[planIndex].payments];
    _payments[index] = newData;
    const plan = { ..._plans[planIndex], payments: _payments };
    _plans[planIndex] = plan;
    //console.log('DimList.setDimensions2:', _dimensions);
    setPlans(_plans);
  }

  const onDeletePaymentRow = (planIndex, paymentIndex) => {
    const _plans = [...plans];
    const _payments = [..._plans[planIndex].payments];
    _payments.splice(paymentIndex, 1);
    const plan = { ..._plans[planIndex], payments: _payments };
    _plans[planIndex] = plan;
    //console.log('DimList.setDimensions2:', _dimensions);
    setPlans(_plans);
  }

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const numberEditor = (options) => {
    return <Input type="number" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Seleccionar Status"
      />
    );
  };  

  const intervalEditor = (options) => {
    return (
      <Tooltip label="0 para enganche, -1 para pago final">
        <Input type="number" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} htmlSize={4} width='100px' size='lg' />
      </Tooltip>
    );
  }

  const percentageEditor = (options) => {
    return (
      <Input 
        type="number" 
        value={options.value} 
        onChange={(e) => options.editorCallback(e.target.value)} 
        htmlSize={4} width='100px' size='lg' 
        max={100} min={0}
      />
    );
  }

  const intervalTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={ periods }
        optionLabel="label"
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  }

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={rowData.status === 'ACTIVE' ? "success" : "danger"}></Tag>;
    //return <div>{rowData.status}</div>;
  };

  const intervalBodyTemplate = (rowData) => {
    return <Text align="center">{rowData.interval_amount}</Text>;
  }

  const numberBodyTemplate = (rowData) => {
    return <Text align="center">{rowData.number}</Text>;
  }

  const exhibitionsBodyTemplate = (rowData) => {
    return <Text align="center">{rowData.exhibitions}</Text>;
  }

  const startBodyTemplate = (rowData) => {
    return <Text align="center">{rowData.start}</Text>;
  }

  const discountBodyTemplate = (rowData) => {
    return <Text>{ rowData.discount_percentage }%</Text>;
  }

  const percentageBodyTemplate = (rowData) => {
    return <Text textAlign={"center"}>{ rowData.percentage }%</Text>;
  }

  const paymentsBodyTemplate = (rowData) => {
    return <Text>{ rowData.payments_total }</Text>;
  }

  const intervalTypeBodyTemplate = (rowData, policyType) => {
    return <div>{ 
      find( periods, { value: rowData.interval_type})?.label }</div>;
  }

  const startTypeBodyTemplate = (rowData, policyType) => {
    return <div>{ 
      find( periods, { value: rowData.start_type})?.label }</div>;
  }  

  const actionsPaymentBodyTemplate = (policyIndex, rowData, props ) => {
    const rowEditor = props.rowEditor;
    if ( rowEditor.editing ) {
      return rowEditor.element;
    } else {
      return (
        <>
          <ButtonPrime onClick={rowEditor.onInitClick} className={rowEditor.initClassName} key={`edit-${rowData.id}`}>
            <span className='p-row-editor-init-icon pi pi-fw pi-pencil p-clickable'></span>
          </ButtonPrime>
          <ButtonPrime 
            type="button"
            onClick={() => onDeletePaymentRow(policyIndex, props.rowIndex)} 
            className={rowEditor.initClassName} 
            key={`delete-${rowData.id}`}
          >
            <span className='p-row-editor-init-icon pi pi-fw pi-trash p-clickable'></span>
          </ButtonPrime>
        </>
      );
    }
  }

  const onChangePlanPriceLists = (index, value ) => {
    // establece las listas de precio que aplican al plan de financiamiento
    let _plans = [...plans];
    let _plan = {..._plans[index], price_lists: value };
    _plans[index] = _plan;
    setPlans(_plans);
  }

  const onChangePlanDescription = (index, value ) => {
    //console.log('onChangePlanDescription:', value);
    let _plans = [...plans];
    let _plan = {..._plans[index], description: value };
    _plans[index] = _plan;
    setPlans(_plans);
  }

  return (
    <Flex direction="column">
      <Tabs variant={"enclosed"}>
        <TabList>
          <Tab>Planes</Tab>
          { plans.length > 0 && plans.map((plan, index) => (
            <Tab key={plan.id}>{plan.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
              <Button
                mb="6"
                rightIcon={<AddIcon />}
                colorScheme="teal"
                onClick={onNewRow}
                ml="auto">
                Nuevo
              </Button>
            </Flex>
            <DataTable 
              key={`tablePlans`}
              id={`tablePlans`}
              //showGridlines 
              value={plans} 
              editMode="row"
              dataKey="id" 
              onRowEditComplete={onRowEditComplete} 
              responsiveLayout="scroll" 
            >
              <Column
                field="name" 
                header="Nombre" 
                editor={(options) => textEditor(options)} 
                style={{ width: "30%"}}
              />
              <Column
                field="discount_percentage" 
                header="Descuento %" 
                editor={(options) => percentageEditor(options)}
                body={discountBodyTemplate}
                style={{ width: "20%"}}
              />
              <Column
                field="payments_total" 
                header="Pagos" 
                editor={(options) => numberEditor(options)}
                body={paymentsBodyTemplate}
                style={{ width: "20%"}}
              />
              <Column
                field="status" 
                header="Status" 
                editor={(options) => statusEditor(options)}
                body={statusBodyTemplate}
                style={{ width: "20%"}}
              />
              <Column 
                rowEditor 
                header="Acciones" 
                headerStyle={{ width: '10%', minWidth: '8rem' }} 
                bodyStyle={{ textAlign: 'center' }}
              />
            </DataTable>
          </TabPanel>
          {plans.length > 0 && plans.map((plan, index) => (
            <TabPanel key={plan.id}>
              <Flex direction={"column"} p={4}>
                { /* La selección de listas de precio se hace siempre */ }
                <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                  <Text fontSize="xl" fontWeight="bold">Listas de precio</Text>
                  <RadioGroup onChange={(e) => onChangePlanPriceLists(index, e)} value={plans[index]?.price_lists}>
                    <Flex direction="row" justifyContent={"flex-start"}>
                      <Radio value="*" size={"lg"} mr={4}>Todas</Radio>
                      <Radio value={
                          plans[index]?.price_lists === '*' ? [] : plans[index]?.price_lists
                        } size={"lg"} mr={4}>Seleccionar</Radio>
                      { plans[index]?.price_lists !== "*" && (
                        <MultiSelect
                          display="chip"
                          className="w-full md:w-20rem"
                          value={plans[index]?.price_lists || []} 
                          onChange={(e) => onChangePlanPriceLists(index, e.value)} 
                          options={price_lists} 
                          optionLabel="name"
                          optionValue="id"
                          placeholder="Seleccionar listas" />
                      )}
                    </Flex>
                  </RadioGroup>
                </Stack>

                <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                  <Text fontSize="xl" fontWeight="bold">Descripción</Text>
                  <Textarea
                    value={plans[index]?.description}
                    onChange={(e) => onChangePlanDescription(index, e.target.value)}
                    placeholder='Descripción larga del plan de pago'
                    size='lg'
                    minHeight={'150px'}
                  />                  
                </Stack>

                  <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
                    <Button
                      mb="6"
                      rightIcon={<AddIcon />}
                      colorScheme="teal"
                      onClick={() => onNewPaymentRow(index)}
                      ml="auto">
                      Nuevo
                    </Button>
                  </Flex>                  
                  <DataTable 
                    key={`tableIntervals-${index}`} 
                    id={`tableIntervals-${index}`} 
                    value={plans[index]?.payments || []} 
                    onRowEditComplete={(e) => onPaymentRowEditComplete(index, e)} 
                    editMode="row" 
                    dataKey="id" 
                    sortField="number"
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="number" 
                      header="Número" 
                      editor={(options) => numberEditor(options)} 
                      body={numberBodyTemplate}
                      style={{ width: "10%"}}
                    />
                    <Column
                      field="start" 
                      header="Inicio"
                      editor={(options) => intervalEditor(options)}
                      body={startBodyTemplate}
                      style={{ width: "10%"}}
                    />
                    <Column
                      field="start_type" 
                      header="Periodo" 
                      editor={(options) => intervalTypeEditor(options)}
                      body={(options) => startTypeBodyTemplate(options)}
                      style={{ width: "10%"}}
                    />
                    <Column
                      field="exhibitions" 
                      header="Exhibiciones" 
                      editor={(options) => numberEditor(options)}
                      body={exhibitionsBodyTemplate}
                      style={{ width: "10%"}}
                      footer={exhibitionsTotals[index]}
                      footerStyle={{ textAlign: 'center' }}
                    />
                    <Column
                      field="interval_amount" 
                      header="Intervalo"
                      editor={(options) => intervalEditor(options)}
                      body={intervalBodyTemplate}
                      style={{ width: "10%"}}
                    />
                    <Column
                      field="interval_type" 
                      header="Periodo" 
                      editor={(options) => intervalTypeEditor(options)}
                      body={(options) => intervalTypeBodyTemplate(options)}
                      style={{ width: "10%"}}
                    />
                    <Column
                      field="percentage" 
                      header="Porcentaje" 
                      editor={(options) => percentageEditor(options)}
                      body={percentageBodyTemplate}
                      style={{ width: "10%"}}
                      footer={`${percentageTotals[index]}%`}
                      footerStyle={{ textAlign: 'center' }}
                    />
                    <Column 
                      rowEditor 
                      body={(rowData, props) => actionsPaymentBodyTemplate(index,rowData, props)}
                      header="Acciones" 
                      headerStyle={{ width: '10%', minWidth: '8rem' }} 
                      bodyStyle={{ textAlign: 'center' }}
                    />    
                  </DataTable>
                
              </Flex>
              </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default FinancingPlans;