import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";

const contactAdapter = createEntityAdapter({
  selectId: (contact) => contact.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const contactSlice = createSlice({
  name: "contact",
  initialState: contactAdapter.getInitialState(),
  reducers: {
    add: contactAdapter.addOne,
    setAll: contactAdapter.setAll,
    update: contactAdapter.upsertOne,
    removeOne: contactAdapter.removeOne,
    empty: contactAdapter.removeAll,
    updateContactPersonalProps(state, action){
        const {id, personal_prop} = action.payload;
        const element = JSON.parse(JSON.stringify(state.entities));
        const contct = element[id];
        if (contct) {
            contct.personal_props = {...contct.personal_props, ...personal_prop };
            contactAdapter.upsertOne(state, contct);
            return;
        }
    },
  },
});

export const { actions } = contactSlice;

export const selectors = contactAdapter.getSelectors((state) => state.contact);