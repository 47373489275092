import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const developmentFinAdapter = createEntityAdapter({
  selectId: (devFin) => devFin.id,
});

export const developmentFinSlice = createSlice({
  name: "developmentFin",
  initialState: developmentFinAdapter.getInitialState(),
  reducers: {
    add: developmentFinAdapter.addOne,
    setAll: developmentFinAdapter.setAll,
    update: developmentFinAdapter.upsertOne,
    removeOne: developmentFinAdapter.removeOne,
    empty: developmentFinAdapter.removeAll,
    updatePropertyJson(state, action){
      const {id, key, value} = action.payload;
      const element = JSON.parse(JSON.stringify(state.entities));
      const ppty = element[id];
      if (ppty) {
          let keys = key.split('__')
          let setter_key = `${keys[0]}`
          if(keys.length > 1){
              keys?.slice(1, keys.length).map((k)=>{
                  setter_key = setter_key + `.${k}`
              })
          }
          const custom_value = set(ppty, setter_key, value);
          developmentFinAdapter.upsertOne(state, custom_value);
          return;
      }
    }
  }
});

export const { actions } = developmentFinSlice;

export const selectors = developmentFinAdapter.getSelectors((state) => state.developmentFin);