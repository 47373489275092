import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { filter } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from 'primereact/inputnumber';
import { Button as ButtonPrime } from "primereact/button";
import { useEffect, useState } from "react";

const PriceListExplosion = (props) => {
  const { data, property_group, property, setData } = props;
  
  const [explosion, setExplosion] = useState(data?.explosion || []);
  const [disabled, setDisabled] = useState(data?.explosion?.lenght > 0);

  useEffect(() => {
    if (data.explosion && data.explosion.length > 0) {
      setExplosion(data.explosion);
      setDisabled(data.explosion.length > 0);
    }
  }, [data]);

  useEffect(() => {
    setData({ ...data, explosion: explosion });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explosion]);

  const explodePropertyGroup = (property_group_id) => {
    // itera entre todos los property_group
    const result = [];
    for (let i=0; i < property_group.length; i++) {
      // si el property_group_id es el master del elemento y aun no esta incluido, agrego su id
      if (property_group[i].property_group_id === property_group_id) {
        result.push(property_group[i].id);
      }
    }
    // si no encontro ninguno, regresa el resultado vacio, de lo contrario ejecuta recursivamente
    const result2 = [];
    for (let i=0; i < result.length; i++) {
      result2.push(...explodePropertyGroup(result[i]));
    }
    return [...result, ...result2];
  }

  const onExplode = () => {
    const { properties, property_models, property_groups } = data
    const exp = [];
    //console.log('properties: ', data);
    if ( Array.isArray(properties) && (properties.length> 0) && (properties[0] === "*")) {
      exp.push(...property);
    } else {
      // agrega las propiedades seleccionadas (si existen)
      exp.push(...filter( property, (item) => properties.includes(item.id) ) );

      // agrega las propiedades que coinciden con los modelos seleccionados
      exp.push(...filter( property, (item) => property_models.includes(item.property_model_id) ) );

      // si existen property_groups seleccionados debo pre-explosionar los ids de los descendientes
      if (Array.isArray(property_groups) && property_groups.length > 0) {
        const finalPropertyGroups = [...property_groups];
        for (const pg of property_groups) {
          finalPropertyGroups.push(...explodePropertyGroup(pg));
        }
        exp.push(...filter( property, (item) => finalPropertyGroups.includes(item.property_group_id) ) );
      }
    }
    if (exp.length > 0) {
      for (let i = 0; i < exp.length; i++) {
        exp[i] = {
          id: exp[i].id,
          name: exp[i].name,//?.substring(exp[i].name?.indexOf('-')+1),
          status: exp[i].status,
          price: exp[i].status !== 'CLOSED' ? exp[i].operation_props.presale.price : null,
        };
      }
      setExplosion(exp);
      setDisabled(exp.length > 0);
    }
  }

  const priceEditor = (options) => {
    //console.log('options: ',options);
    return (
      <InputNumber 
        value={options.value} 
        onValueChange={(e) => options.editorCallback(e.value)} 
        mode="currency" 
        currency="MXN" 
        locale="es-MX" 
      />);
  };  

  const priceBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' })
      .format(rowData?.price);
  }

  const nameBodyTemplate = (rowData) => {
    const name =rowData?.name;
    return name?.substring(name?.indexOf('-')+1);
  }

  const statusBodyTemplate = (rowData) => {
    return rowData?.status || null;
  }

  const deleteRow = (rowData) => {
    const deletedData = explosion.filter(item => { return (item.id !== rowData.id) });
    //setData(masterId, deletedData );
    setExplosion(deletedData);
  }

  const onRowEditComplete = (e) => {
    
    let _explosion = [...explosion];
    let { newData, index } = e;
    //console.log('newData: ',newData);
    _explosion[index] = {...newData, price: newData["price"]}; 
    //setData(masterId, _explosion);
    setExplosion(_explosion);
  }  

  const actionsBodyTemplate = (rowData, props ) => {
    const rowEditor = props.rowEditor;
    if ( rowEditor.editing ) {
      return rowEditor.element;
    } else {
      return (
        <>
          <ButtonPrime onClick={rowEditor.onInitClick} className={rowEditor.initClassName} key={`edit-${rowData.id}`}>
            <span className='p-row-editor-init-icon pi pi-fw pi-pencil p-clickable'></span>
          </ButtonPrime>
          <ButtonPrime onClick={() => deleteRow(rowData)} className={rowEditor.initClassName} key={`delete-${rowData.id}`}>
            <span className='p-row-editor-init-icon pi pi-fw pi-trash p-clickable'></span>
          </ButtonPrime>
        </>
      );
    }
  }  

  return (
    <Flex direction={"column"}>
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          mb="6"
          rightIcon={<AddIcon />}
          colorScheme="teal"
          disabled={disabled}
          onClick={onExplode}
          ml="auto">
          Explosionar
        </Button>
      </Flex>
      <DataTable
        scrollable scrollHeight="400px"
        id="tableExplosion"
        value={explosion} 
        editMode="row"
        dataKey="id" 
        onRowEditComplete={onRowEditComplete} 
        responsiveLayout="scroll" 
        //selection={selected}
        //onSelectionChange={(e) => setSelected(e.value)}
      >
        <Column field="name" header="Propiedad" body={nameBodyTemplate} sortable style={{ width: '25%' }}/>
        <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ width: '25%' }}/>
        <Column field="price" header="Precio" body={priceBodyTemplate} sortable style={{ width: '25%' }}
          editor={(options) => priceEditor(options)}
        />
        <Column 
          rowEditor 
          body={actionsBodyTemplate}
          header="Acciones" 
          headerStyle={{ width: '25%', minWidth: '8rem' }} 
          bodyStyle={{ textAlign: 'center' }}
        />
      </DataTable>

    </Flex>
  )
}

export default PriceListExplosion;