import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import { createStandaloneToast } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';
//import { hydrate, loginUser } from './store/userSlice';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from './keycloak';
import { tokensCallback } from './store/boomerangApi';

const { ToastContainer, toast } = createStandaloneToast();

/*
// recupera las credenciales del localStorage
const getAuthFromLocalStorage = () => {
  try {
    const persistedState = JSON.parse(localStorage.getItem('reduxState'))
    if (persistedState)
      return persistedState
  }
  catch (e) {
    console.log(e)
  }
}
*/
// si existen credenciales guardadas, las mete al state
// const auth = getAuthFromLocalStorage();
// if (auth) {
//   if (Object.keys(auth.user).length !== 0) {
//     store.dispatch(hydrate(auth));
//   }
// }

let persistor = persistStore(store)

/*
const tokenCallback = (tokens ) => {
  console.log('guardando el token', tokens);
  const state = {}
  state.user = {
    //email: payload.email,
    //name: payload.name,
    token: tokens.token,
    refresh_token: tokens.refreshToken,
  };
  state.isFetching = false;
  state.isSuccess = true;
  state.isLoggedOut = false;
  // Store credentials in local storage
  localStorage.setItem("reduxState", JSON.stringify(state))
}

const eventCallback = (event, error) => {
  console.log('keycloakEvent: ', event, error );
}
*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactKeycloakProvider 
    authClient={keycloak} 
    initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
    onTokens={tokensCallback} //onEvent={eventCallback}
  >
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ToastContainer />
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </StrictMode>
  </ReactKeycloakProvider>
);

toast({ title: 'Boomerang' });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
