import { get, remove } from 'lodash';
import { cloneDeep } from 'lodash';

/*
    funciones para hacer mas simple la organización de arrays anidados
    todas las funciones esperan que siempre se tenga una propiedad id
*/


// busca recursivamente en un array anidado a un elemento identificado por id y lo borra
const recursiveFindAndRemove = (arr, nestedArrName, id, borrar = true) => {
  let result = false;
  if (arr && arr.length > 0) {
    for(const item of arr) {
      if (item.id === id) {
        if (borrar) {
          remove(arr, elem => elem.id === id);
        }
        result = true;
        break;
      } else {
        if (item[nestedArrName] && item[nestedArrName].length > 0) {
          result = recursiveFindAndRemove(item[nestedArrName], nestedArrName, id);
        }
      }
    };
  }
  return result;
}

const recursiveFindAndGet = (arr, nestedArrName, id ) => {
  let result = undefined;
  if (arr && arr.length > 0) {
    for(const item of arr) {
      if (item.id === id){
        result = cloneDeep(item);
        break;
      } else {
        if (item[nestedArrName] && item[nestedArrName].length > 0) {
          result = recursiveFindAndGet(item[nestedArrName], nestedArrName, id);
        }
      }
    }
  }
  return result;
}

// busca recursivamente al parent de un array para insertarlo en el array anidado correspondiente
const recursiveFindAndInsert = (arr, nestedArrName, parentId, arrToInsert ) => {
  let result = false;
  if (arr && arr.length > 0) {
    for(const item of arr) {
      if (item.id === parentId) {
        if (!item.hasOwnProperty(nestedArrName)) {
          item[nestedArrName] = [];
        }
        if (!item[nestedArrName].find( el => el.id === arrToInsert.id )) {
          item[nestedArrName].push(arrToInsert);
        }
        result = true;
        break;
      } else {
        if (item[nestedArrName] && (item[nestedArrName].length > 0)) {
          result = recursiveFindAndInsert(item[nestedArrName], nestedArrName, parentId, arrToInsert );
        }
      }
    };
  }
  return result;
}

/* En un array anidado ( arr ), busca el elemento item identificado por id 
   a traves de los arrays anidados identificados por nestedArrName para eliminarlo
   y despues insertarlo en el padre identificado por el valor de parentIdPropName
*/
export const modifyNestedArrayItem = (arr, item, nestedArrName, parentIdPropName) => {
  const _arr = cloneDeep(arr);
  const _item = cloneDeep(item);
  const result = recursiveFindAndRemove(_arr, nestedArrName, item.id );
  if ( result ) {
    recursiveFindAndInsert(_arr, nestedArrName, item[parentIdPropName], _item );
  }
  return _arr;
}

export const upsertNestedArrayItem = (arr, item, nestedArrName, parentIdPropName) => {
  const _arr = cloneDeep(arr);
  const _item = cloneDeep(item);
  const result = recursiveFindAndRemove(_arr, nestedArrName, item.id, false );
  if ( result ) {
    recursiveFindAndRemove(_arr, nestedArrName, item.id );
  }
  recursiveFindAndInsert(_arr, nestedArrName, item[parentIdPropName], _item );
  return _arr;
}

export const getNedtedArrayItem = (arr, nestedArrName, id ) => {
  return recursiveFindAndGet(arr, nestedArrName, id);
}

/*  En un array anidado (arr), agregar un elemento (elem) como un child del array (nestedArrName)
    identificado ( parentIdName ) por un valor (parentId)
*/
export const addNestedArrayItem = (arr, item, nestedArrName, parentId, parentIdPropName ) => {
  const _arr = cloneDeep(arr);
  // si no tiene valor en parentIdPropName lo agrega al array raíz y sale
  if (!item[parentIdPropName]) {
    _arr.push(item);
  } else {
    recursiveFindAndInsert(_arr, nestedArrName, parentId, item );
  }
  return _arr;
}

/* reorganiza un array(arr), para que los elementos del array anidado nestedArrName
    sean reubicados dentro del item identificado por parentPropName
*/
export const arrangeNestedArray = (arr, nestedArrName, parentIdPropName ) => {
  const _arr = cloneDeep(arr);
  const removed = [];
  _arr.forEach(item => {
    const parent_id = get(item, parentIdPropName);
    if (  item.id
          && parent_id
          && (!removed.includes(item.id))
        ) {
      const found = recursiveFindAndInsert(_arr, nestedArrName, parent_id, item );
      //console.log(`item.id=${item.id}, parent_id=${parent_id}, found=${found}`);
      if (found) {
        removed.push(item.id);
      }
    }
  });
  //console.log('removed:', removed );
  remove(_arr, item => removed.includes(item.id));
  return _arr;
}
