export const REACT_APP_API_ENDPOINT = 
process.env.NODE_ENV !== "production"
//? "http://localhost:4000"
? "https://develop.grupoguia.mx/api2"
: "https://api.grupoguia.mx/v2"

export const REACT_APP_REDIRECT = 
process.env.NODE_ENV !== "production"
//? "http://localhost:3000/dashboard"
? "https://devtech.grupoguia.mx/dashboard"
: "https://tech.grupoguia.mx/dashboard"