import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../store/developmentSlice";
import { actions as actionsFin, selectors as selectorsFin } from "../../store/developmentFinSlice";
import { actions as actionsDraft, selectors as selectorsDraft } from "../../store/genericSlice";
import { v4 as uuidv4 } from "uuid";
import { get, isEqual } from "lodash";
import { ArrowBackIcon, ArrowForwardIcon, EditIcon, Search2Icon, SmallAddIcon } from "@chakra-ui/icons";
import { useGetEntityQuery, useGetFormsByCategoryQuery, usePostEntityMutation } from "../../store/boomerangApi";
import { SchemaForm, SchemaTable } from "@grupo-guia/boomerang-schema-ui";
import PolicyList from "../components/policyList";
import FinancingPlans from "../components/financingPlans";
import DiscountList from "../components/discountList";


const RowStatus = (props) => {
  return (
    <Box
      fontWeight={800}
      px="2"
      rounded="full"
      textAlign="center"
      color="white"
      py="1"
      bg={props.dataColumn === "ACTIVE" ? "green.500" : "red.500"}
      fontSize="sm">
      {props.dataColumn}
    </Box>
  );
};

const codeCategoryForm = "TECH-FIN";
const entity = "development";
const entityFin = "development_financing";

const DesarrollosFin = () => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [editId, setEditId] = useState(null);
  const [editFinId, setEditFinId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [tableInfo, setTableInfo] = useState({});
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { data: dataDev, isLoading } = useGetEntityQuery(
    { 
      entity, take: 10, skip: page*10, 
      where: { name : { contains: search?search.toUpperCase():'' }},
      include: { development_financing: true }
    }
  ); // fetch entity array
  const { data: dataFin } = useGetEntityQuery({ entity: entityFin, where: { development_id: editId }});

    // fetch ui & entity with redux toolkit query
    const { data: dataForms } = useGetFormsByCategoryQuery({
      code: codeCategoryForm,
    });

    // fetch price lists
    const { data: dataPriceLists } = useGetEntityQuery({
      entity: "price_list",
      take: 100,
      skip: 0,
      where: { development_id : editId, status: "ACTIVO" },
      select: { id: true, name: true },
    })
  
    const [postEntity] = usePostEntityMutation();

    const draftDev = useSelector((state) => selectorsDraft.selectById(state, editId ));
    const draftFin = useSelector((state) => selectorsDraft.selectById(state, editFinId));

    const dataDevs = useSelector(selectors.selectAll);
    const dataFins = useSelector(selectorsFin.selectAll);
  
    const handleSearch = () => {
      setSearch(inputValue)
    }
  
  // modal handlers
  const onNew = () => {
    const newData = { id: uuidv4() };
    const newFinData = { id: uuidv4(), development_id: newData.id, policy_props: [], plan_props: [], discount_props: [] };
    dispatch(actionsDraft.add({ ...newData }));
    dispatch(actionsDraft.add({ ...newFinData }));
    setEditId(newData.id);
    setEditFinId(newFinData.id);
    onOpen();
  };

  const onEdit = (dataRow) => {
    const editDev = dataDevs.find((item) => item.id === dataRow.id);
    const editFin = editDev.development_financing;
    const editFinId = editFin?.id;

    dispatch(actionsDraft.update(editDev));

    if (editFin) {
      dispatch(actionsDraft.add(editFin));
    }

    setEditId(dataRow.id);
    if (!editFin)
      //(!dataArchs || dataArchs.length < 1) 
      {
      const newFinData = { id: uuidv4(), development_id: dataRow.id, policy_props: [] , plans_props: [], discount_props: [] };
      dispatch(actionsDraft.add({ ...newFinData }));
      setEditFinId(newFinData.id);
    } else {
      setEditFinId(editFinId);
    }
    onOpen();
  }

  const onSave = async (e) => {
    e.preventDefault();
    try {
      console.log('arch:', draftFin);
      //const response = await postEntity({ entity, ...draftDev });
      const respArch = await postEntity({ entity: entityFin, ...draftFin });
      //dispatch(actions.update(response.data));
      dispatch(actionsFin.update(respArch.data));
      setEditId(null);
      setEditFinId(null);
      dispatch(actionsDraft.empty());
    } catch (error) {
      console.log("error:", error);
    }
    onClose();
  };

  useEffect(() => {
    if (dataForms) {
        //console.log(dataForms)
        const [objTable, objSchemaGeneral, objSchemaLocationProps] = dataForms;
        setTableInfo(objTable || {});
        //setSchemaDevelopment(objSchemaGeneral || {});
        //setSchemaPriceListProps(objSchemaLocationProps || {})
    }
  }, [dataForms]);

  useEffect(() => {
    if (tableInfo?.properties) {
      const fieldsToShow = get(tableInfo, "properties.fieldsToShow", []);
      const fieldLabels = get(tableInfo, "properties.fieldLabels", []);
      const mapped = fieldsToShow.map((field, i) => {
        return { key: field, text: fieldLabels[i] || field };
      });
      setColumns(mapped.concat([{ key: "actions", text: "Acciones" }]));
    }
  }, [tableInfo, tableInfo?.properties]);

  useEffect(() => {
    if (Array.isArray(dataDev) && dataDev.length > 0) {
        dispatch(actions.setAll(dataDev));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDev]);

  useEffect(() => {
    if (Array.isArray(dataFin) && dataFin.length > 0) {
      console.log('dataFin:', dataFin);
      dispatch(actionsFin.setAll(dataFin));
      dispatch(actionsDraft.update(dataFin[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFin])

  const HeaderTable = (props) => {
    return (
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          rightIcon={<SmallAddIcon />}
          colorScheme="teal"
          onClick={onNew}
          ml="auto">
          Nuevo
        </Button>
      </Flex>
    );
  };

  const EditComponent = ({ dataRow }) => {
    return (
      <IconButton
        colorScheme="blue"
        aria-label="Editar fila"
        icon={<EditIcon />}
        
        onClick={() => {
          onEdit(dataRow);
        }}
      />
    );
  };

  const developmentFormHandler = (key, inputValue) => {
    console.log(dispatch(actionsDraft.updatePropertyJson({ id: editId, key: key,  value: inputValue })));
  };

  const devFinFormHandler = (key, inputValue) => {
    const tmp = { ...draftFin, id: editFinId };
    tmp[key] = inputValue;
    console.log(dispatch(actionsDraft.update(tmp)));
 }

  const onSetPolicies = (data) => { 
    const policyProps = data;
    
    if (draftFin.policy_props && !isEqual(draftFin.policy_props, data)) {
      devFinFormHandler( 'policy_props', policyProps );
    }    
  }

  const onSetPlans = ( data ) => {
    const plansProps = data;
    
    if (draftFin.plans_props && !isEqual(draftFin.plans_props, data)) {
      devFinFormHandler( 'plans_props', plansProps );
    }    
  }

  const onSetDiscounts = ( data ) => {
    // establece los descuentos adicionales
    console.log('onSetDiscounts:', data);
    const discountProps = data;
    
    if (draftFin.discount_props && !isEqual(draftFin.discount_props, data)) {
      console.log('onSetDiscountsSend:', discountProps);
      devFinFormHandler( 'discount_props', discountProps );
    }        
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>{draftDev?.name} - Financiamiento</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={onSave}>
            <ModalBody pb={6}>
            <Tabs variant='enclosed'>
              <TabList>
                  {/*
                  <Tab>Lista de precios Activa</Tab> */}
                  <Tab>Políticas de Precios</Tab>
                  <Tab>Planes de financiamiento</Tab>
                  <Tab>Descuentos Adicionales</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <PolicyList
                    data={draftFin?.policy_props || []}
                    setData={onSetPolicies}
                    price_lists={dataPriceLists}
                  />
                </TabPanel>
                <TabPanel>
                  <FinancingPlans 
                  data={draftFin?.plans_props || []}
                  setData={onSetPlans}
                  price_lists={dataPriceLists}
                  />
                </TabPanel>
                <TabPanel>
                  <DiscountList
                  data={draftFin?.discount_props || []}
                  setData={onSetDiscounts}
                  price_lists={dataPriceLists}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                    Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Box max="6xl" mx="auto" mt="2" pt={0} pb={0}>
        {isLoading === true && (
          <Center h='100px'>
            <Spinner color='yellow.400' size='lg' />
          </Center>
        )}
        {isLoading === false && (
          <Box bg="white" p="2">
            <Stack float={'right'} py={4}>
              <Grid templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}>
                <GridItem colSpan={2}>
                  <Input onChange={(e)=>{e.preventDefault(); setInputValue(e.target.value)}}/>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button onClick={(e)=>{e.preventDefault(); handleSearch();}}>
                    <Search2Icon />
                  </Button>
                </GridItem>
              </Grid>
            </Stack>
            {tableInfo.properties ?
              <SchemaTable
                headerTable={HeaderTable}
                title="Financiamiento para desarrollos"
                columns={columns}
                data={dataDevs}
                Rowstatus={RowStatus}
                Rowactions={EditComponent}
                tabkey="name"
                tabTitle="Financiamiento"
              />
            :
              <Box ml={'310px'}>
                <Center h='100px'>
                  <Spinner color='yellow.400' size='xl' />
                </Center> 
              </Box>
            }
            <Stack>
              <Grid templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}>
                <GridItem colSpan={{ md: 1, base: 0 }}>
                </GridItem>
                <GridItem colSpan={1}>
                  <Grid templateColumns={"repeat(3, 1fr)"}>
                    <GridItem colSpan={1} textAlign='end'>
                      <Button onClick={(e)=>{e.preventDefault(); setPage(page - 1)}} disabled={page===0?true:false}>
                        <ArrowBackIcon/>
                      </Button>
                    </GridItem>
                    <GridItem colSpan={1} textAlign='center' alignItems={'center'}>
                      <Text fontSize={'lg'} fontWeight='500'>Página {page + 1}</Text>
                    </GridItem>
                    <GridItem colSpan={1} textAlign='start'>
                      <Button onClick={(e)=>{e.preventDefault(); setPage(page + 1)}} disabled={dataDevs?.length < 10?true:false}>
                        <ArrowForwardIcon />
                      </Button>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem colSpan={{ md: 1, base: 0 }}>
                </GridItem>
              </Grid>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );

}

export default DesarrollosFin;