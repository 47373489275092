import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { cloneDeep, remove } from "lodash";

const companyAdapter = createEntityAdapter({
  selectId: (company) => company.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const companySlice = createSlice({
  name: "company",
  initialState: companyAdapter.getInitialState(),
  reducers: {
    add: companyAdapter.addOne,
    setAll: companyAdapter.setAll,
    update: companyAdapter.upsertOne,
    removeOne: companyAdapter.removeOne,
    companyFetchedDist(state, action) {
      // agregar todas la entidades de company pero redistribuir los
      // company_department anidados dentro de otros company_department
      const data = cloneDeep(action.payload);
      console.log("data:", data);

      // funcion recursiva para copiar un department en el parent que le corresponde
      function findItIn(dptArr, dptId, dep) {
        console.log(`dptId: ${dptId}`);
        // en el array dptArr, buscar el dptId y meterle ( push ) el dep
        let result = false;

        if (dptArr && dptArr.length > 0) {
          dptArr.forEach((item, idx) => {
            // si es el que busco
            if (item.id === dptId) {
              console.log("found:", item);
              // si aun no lo tiene el array company_department lo crea
              if (!item.company_department) {
                dptArr[idx].company_department = [];
              }
              console.log("push:", dep);
              dptArr[idx].company_department.push(dep);
              result = true;
            } else {
              // si no es el que busco, veo si tiene children company_department
              // para que busque en los children
              if (
                item.company_department &&
                item.company_department.length > 0
              ) {
                result = findItIn(item.company_department, dptId, dep);
              }
            }
          });
        }
        return result;
      }

      // iterar en todas las company
      data.forEach((cmp) => {
        if (cmp.company_department && cmp.company_department.length > 0) {
          // iterar en todos los company_department
          cmp.company_department.forEach((dpt) => {
            // si tiene valor en company_deparment_id, debe redistribuirse
            if (dpt.company_department_id) {
              const dptClone = cloneDeep(dpt);

              // encontrar en donde debe ir y enviarlo a donde debe ir
              const found = findItIn(
                cmp.company_department,
                dpt.company_department_id,
                dptClone
              );

              // si lo encontró, lo borrar del array del primer nivel
              if (found) {
                remove(
                  cmp.company_department,
                  (item) => item.id === dptClone.id
                );
              }
            }
          });
        }
      });
      // enviarlo a setAll
      companyAdapter.setAll(state, data);
    },
    departmentUpdate(state, action) {
      // debido a que los deparments es un detail ( chindren ) de company
      // es necesario actualizar el array company_department que se encuentra dentro
      // del elemento company al que pertenece

      // funcion recursiva para upsert un department en el parent que le corresponde
      // en caso de requerirlo
      function findAndUpsert(dptArr, dptId, dep) {
        console.log(`dptId: ${dptId}`);
        // en el array dptArr, buscar el dptId y meterle ( push ) el dep
        let result = false;

        if (dptArr && dptArr.length > 0) {
          dptArr.forEach((item, idx) => {
            // si es el que busco
            if (item.id === dptId) {
              // si aun no lo tiene el array company_department lo crea e inserta
              if (
                !item.company_department ||
                item.company_department.length < 1
              ) {
                dptArr[idx].company_department = [];
                dptArr[idx].company_department.push(dep);
              } else {
                // si ya tiene el array voy a ver si ya está creado para actualizarlo
                if (
                  item.company_department &&
                  item.company_department.length > 0
                ) {
                  const dptIdx = item.company_deparment.findIndex(
                    (itm) => itm.id === dep.id
                  );
                  if (dptIdx >= 0) {
                    // si ya lo tiene
                    dptArr[idx].company_department[dptIdx] = dep;
                  } else {
                    // si no lo tiene
                    dptArr[idx].company_department.push(dep);
                  }
                }
              }
              result = true;
            } else {
              // si no es el que busco, veo si tiene children company_department
              // para que busque en los children
              if (
                item.company_department &&
                item.company_department.length > 0
              ) {
                result = findAndUpsert(item.company_department, dptId, dep);
              }
            }
          });
        }
        return result;
      }

      // hacer liquido el state ( por algun motivo no funciona directo de state )
      const element = JSON.parse(JSON.stringify(state.entities));

      //tomar el company
      const id = action.payload.company_id;
      const dpt = action.payload;
      const cmp = element[id];

      // si no es sub-departamento de otro
      if (cmp && cmp.company_department && dpt.company_department_id === null) {
        // buscar el deparment en el que se creó
        const idx = cmp.company_department.findIndex(
          (item) => item.id === action.payload.id
        );
        if (idx >= 0) {
          const dpt = cmp.company_department[idx];
          const tmp = { ...action.payload };
          cmp.company_department[idx] = { ...dpt, ...tmp };
          companyAdapter.upsertOne(state, cmp);
          return;
          // si no se encontró, debe ser uno nuevo
        } else {
          cmp.company_department.push({ ...action.payload });
          //console.log("cmp2:", cmp);
          companyAdapter.upsertOne(state, cmp);
          return;
        }
      }

      // si es sub-departamento de otro se va a requerir de la busqueda recursiva
      if (cmp && cmp.company_department && dpt.company_department_id) {
        const response = findAndUpsert(
          cmp.company_deparment,
          dpt.company_department_id,
          dpt
        );
        if (response) {
          companyAdapter.upsertOne(state, cmp);
        }
      }
    },
    contactPropUpdate(state, action) {
      // cuando se agrega o modificar un puesto ( contact_prop ) de un departamento
    },
  },
});

export const { actions } = companySlice;

export const selectors = companyAdapter.getSelectors((state) => state.company);
