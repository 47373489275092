import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetEntityQuery, useGetFormsByCategoryQuery, usePostEntityMutation } from "../../store/boomerangApi";
import { actions, selectors } from "../../store/developmentSlice";
import { actions as actionsArch, selectors as selectorsArch } from "../../store/developmentArchSlice";
import { actions as actionsDraft, selectors as selectorsDraft } from "../../store/genericSlice";
import { v4 as uuidv4 } from "uuid";
import { get, isEqual } from "lodash";
import { ArrowBackIcon, ArrowForwardIcon, EditIcon, Search2Icon, SmallAddIcon } from "@chakra-ui/icons";
import { SchemaForm, SchemaTable } from "@grupo-guia/boomerang-schema-ui";
import StageList from "../components/stageList";
import DimensionList from "../components/dimensionList";
import ExplosionList from "../components/explosionList";

const RowStatus = (props) => {
  return (
    <Box
      fontWeight={800}
      px="2"
      rounded="full"
      textAlign="center"
      color="white"
      py="1"
      bg={props.dataColumn === "ACTIVE" ? "green.500" : "red.500"}
      fontSize="sm">
      {props.dataColumn}
    </Box>
  );
};

const codeCategoryForm = "TECH-DEV";
const entity = "development";
const entityArch = "development_architectural";

const DesarrollosTech = () => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [editId, setEditId] = useState(null);
  const [editArchId, setEditArchId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [tableInfo, setTableInfo] = useState({});
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState(0);

  const [schemaDevelopment, setSchemaDevelopment] = useState({});
  const [schemaExternalProps, setSchemaLocationProps] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { data: dataDev, isLoading } = useGetEntityQuery(
    { 
      entity, take: 10, skip: page*10, 
      where: { name : { contains: search?search.toUpperCase():'' }},
      include: { development_architectural: true }
    }
  ); // fetch entity array
  const { data: dataArch } = useGetEntityQuery({ entity: entityArch, where: { development_id: editId }});

    // fetch ui & entity with redux toolkit query
  const { data: dataForms } = useGetFormsByCategoryQuery({
    code: codeCategoryForm,
  });

  const [postEntity] = usePostEntityMutation();

  const draftDev = useSelector((state) => selectorsDraft.selectById(state, editId ));
  const draftArch = useSelector((state) => selectorsDraft.selectById(state, editArchId));

  const dataDevs = useSelector(selectors.selectAll);
  const dataArchs = useSelector(selectorsArch.selectAll);

  const handleSearch = () => {
    setSearch(inputValue)
  }

  // modal handlers
  const onNew = () => {
    const newData = { id: uuidv4() };
    const newArcData = { id: uuidv4(), development_id: newData.id, stages_props: [] };
    dispatch(actionsDraft.add({ ...newData }));
    dispatch(actionsDraft.add({ ...newArcData }));
    setEditId(newData.id);
    setEditArchId(newArcData.id);
    onOpen();
  };

  const onEdit = (dataRow) => {
    const editDev = dataDevs.find((item) => item.id === dataRow.id);
    const editArch = editDev.development_architectural;
    const editArchId = editArch?.id;

    dispatch(actionsDraft.update(editDev));

    if (editArch) {
      dispatch(actionsDraft.add(editArch));
    }

    setEditId(dataRow.id);
    if (!editArch)
      //(!dataArchs || dataArchs.length < 1) 
      {
      const newArcData = { id: uuidv4(), development_id: dataRow.id, stages_props: { stages: [] }, fractional_structure_props: [] };
      dispatch(actionsDraft.add({ ...newArcData }));
      setEditArchId(newArcData.id);
    } else {
      setEditArchId(editArchId);
    }
    onOpen();
  }

  const onSave = async (e) => {
    e.preventDefault();
    try {
      console.log('arch:', draftArch);
      const response = await postEntity({ entity, ...draftDev });
      const respArch = await postEntity({ entity: entityArch, ...draftArch });
      dispatch(actions.update(response.data));
      dispatch(actionsArch.update(respArch.data));
      setEditId(null);
      setEditArchId(null);
      dispatch(actionsDraft.empty());
    } catch (error) {
      console.log("error:", error);
    }
    onClose();
  };

  useEffect(() => {
    if (dataForms) {
        //console.log(dataForms)
        const [objTable, objSchemaGeneral, objSchemaLocationProps] = dataForms;
        setTableInfo(objTable || {});
        setSchemaDevelopment(objSchemaGeneral || {});
        setSchemaLocationProps(objSchemaLocationProps || {})
    }
  }, [dataForms]);

  useEffect(() => {
    if (tableInfo?.properties) {
      const fieldsToShow = get(tableInfo, "properties.fieldsToShow", []);
      const fieldLabels = get(tableInfo, "properties.fieldLabels", []);
      const mapped = fieldsToShow.map((field, i) => {
        return { key: field, text: fieldLabels[i] || field };
      });
      setColumns(mapped.concat([{ key: "actions", text: "Acciones" }]));
    }
  }, [tableInfo, tableInfo?.properties]);

  useEffect(() => {
    if (Array.isArray(dataDev) && dataDev.length > 0) {
        dispatch(actions.setAll(dataDev));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDev]);

  useEffect(() => {
    if (Array.isArray(dataArch) && dataArch.length > 0) {
      console.log('dataArch:', dataArch);
      dispatch(actionsArch.setAll(dataArch));
      dispatch(actionsDraft.update(dataArch[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataArch])

  const HeaderTable = (props) => {
    return (
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          rightIcon={<SmallAddIcon />}
          colorScheme="teal"
          onClick={onNew}
          ml="auto">
          Nuevo
        </Button>
      </Flex>
    );
  };

  const EditComponent = ({ dataRow }) => {
    return (
      <IconButton
        colorScheme="blue"
        aria-label="Editar fila"
        icon={<EditIcon />}
        
        onClick={() => {
          onEdit(dataRow);
        }}
      />
    );
  };

  const developmentFormHandler = (key, inputValue) => {
    console.log(dispatch(actionsDraft.updatePropertyJson({ id: editId, key: key,  value: inputValue })));
  };

  const devArchFormHandler = (key, inputValue) => {
    const tmp = { id: editArchId };
    tmp[key] = inputValue;
    console.log(dispatch(actionsDraft.update(tmp)));
 }

  const onSetStages = (data) => {
    const _data = data.map((item) => {
      if (item.start instanceof Date) {
        item.start = item.start.toISOString().split('T')[0];
      }
      if (item.end instanceof Date) {
        item.end = item.end.toISOString().split('T')[0];
      }
      return item;
    });
    
    const stagesProps = { stages: _data, stages_total: _data.length };
    
    if (draftArch.stages_props && !isEqual(draftArch.stages_props?.stages, data)) {
      devArchFormHandler( 'stages_props', stagesProps );
    }    
  }

  const onSetDimensions = ( stageId, data ) => {
    // reemplaza las dimensiones que cambiaron en la estructrura del state local
    const _dim = draftArch.fractional_structure_props?.find((item) => item.stage_id === stageId )?.dimensions;

    // cuando ya existen dimensiones
    if ( 
      _dim && data &&
      //Array.isArray(draftArch.fractional_structure_props?.dimensions) && 
      !isEqual(_dim, data)) {
      const newStruc = draftArch.fractional_structure_props.map((item) => {
        if (item.stage_id === stageId) {
          return { stage_id: stageId, dimensions: data, explosion: [...(item.explosion || [])]};
        } else {
          return item;
        }
      });
      devArchFormHandler('fractional_structure_props', newStruc);
    } 
    // cuando no existen dimensiones
    if ( !_dim) {
      devArchFormHandler('fractional_structure_props', 
        [{ stage_id: stageId, dimensions: data, adios: "adios" }],
      );
    }
  }

  const onSetExplosion = ( stageId, data ) => {
    // reemplaza laexplosion
    const _exp = draftArch.fractional_structure_props?.find((item) => item.stage_id === stageId )?.explosion;

    // cuando ya existen dimensiones
    if ( 
      _exp && data &&
      //Array.isArray(draftArch.fractional_structure_props?.dimensions) && 
      !isEqual(_exp, data)) {
      const newStruc = draftArch.fractional_structure_props.map((item) => {
        if (item.stage_id === stageId) {
          return { stage_id: stageId, dimensions: [...(item.dimensions || [])], explosion: data};
        } else {
          return item;
        }
      });
      devArchFormHandler('fractional_structure_props', newStruc);
    } 
    // cuando no existen dimensiones
    if ( !_exp) {
      devArchFormHandler('fractional_structure_props', 
        [{ stage_id: stageId, explosion: data, hola: "hola"}],
      );
    }
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Información del Desarrollo</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={onSave}>
            <ModalBody pb={6}>
            <Tabs variant='enclosed'>
              <TabList>
                  <Tab>Datos Generales</Tab>
                  <Tab>Datos Externos</Tab>
                  <Tab>Etapas del Desarrollo</Tab>
                  {(draftArch) && (draftArch.stages_props) && (Array.isArray(draftArch.stages_props.stages)) ?
                    draftArch.stages_props?.stages.map((stage) => (
                    <Tab>{stage.stage_name}</Tab>
                  )) : (
                    <></>
                  )}
              </TabList>
              <TabPanels>
                <TabPanel>
                    <SchemaForm
                        schema={schemaDevelopment}
                        customModelSetter={developmentFormHandler}
                        customModelValues={editId && draftDev ? draftDev : {}}
                        classNames={{
                        templateColumns: { md: "repeat(2, 1fr)", base: "1fr" },
                        gap: { md: 3, base: 2 },
                        mt: "2",
                        }}
                    />
                </TabPanel>
                <TabPanel>
                  <SchemaForm
                    schema={schemaExternalProps}
                    customModelSetter={developmentFormHandler}
                    customModelValues={editId && draftDev?.location_props ? draftDev : {}}
                    classNames={{
                    templateColumns: { md: "repeat(2, 1fr)", base: "1fr" },
                    gap: { md: 3, base: 2 },
                    mt: "2",
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <StageList 
                    data={
                      (draftArch) 
                      && (draftArch.stages_props) 
                      && (Array.isArray(draftArch.stages_props?.stages)) 
                        ? draftArch.stages_props.stages 
                        : []
                    } 
                    setData={onSetStages} />
                </TabPanel>
                {(draftArch) && (draftArch.stages_props) && (Array.isArray(draftArch.stages_props?.stages)) ?
                  draftArch.stages_props?.stages.map((stage) => (
                  <TabPanel>
                    <Tabs variant="enclosed" >
                      <TabList>
                        <Tab>Dimensiones</Tab>
                        <Tab>Explosión</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel key={`dim-${stage.stage_id}`}>
                          <DimensionList 
                            data={
                              draftArch.fractional_structure_props?.find(
                                (item) => item.stage_id === stage.stage_id
                              )?.dimensions || []
                            } 
                            setData={onSetDimensions}
                            masterId={stage.stage_id}
                          />
                        </TabPanel>
                        <TabPanel key={`exp-${stage.stage_id}`}>
                          <ExplosionList
                            data={
                              draftArch.fractional_structure_props?.find(
                                (item) => item.stage_id === stage.stage_id
                              )?.explosion || []
                            }
                            setData={onSetExplosion}
                            masterId={stage.stage_id}
                            dimensions={
                              draftArch.fractional_structure_props?.find(
                                (item) => item.stage_id === stage.stage_id
                              )?.dimensions || []
                            }
                            devCode={draftDev.code}
                          />
                        </TabPanel>
                        </TabPanels>
                    </Tabs>
                  </TabPanel>
                )) : (
                  <></>
                )}
              </TabPanels>
            </Tabs>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                    Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Box max="6xl" mx="auto" mt="2" pt={0} pb={0}>
        {isLoading === true && (
          <Center h='100px'>
            <Spinner color='yellow.400' size='lg' />
          </Center>
        )}
        {isLoading === false && (
          <Box bg="white" p="2">
            <Stack float={'right'} py={4}>
              <Grid templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}>
                <GridItem colSpan={2}>
                  <Input onChange={(e)=>{e.preventDefault(); setInputValue(e.target.value)}}/>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button onClick={(e)=>{e.preventDefault(); handleSearch();}}>
                    <Search2Icon />
                  </Button>
                </GridItem>
              </Grid>
            </Stack>
            {tableInfo.properties ?
              <SchemaTable
                headerTable={HeaderTable}
                title="Dimensiones para desarrollos"
                columns={columns}
                data={dataDevs}
                Rowstatus={RowStatus}
                Rowactions={EditComponent}
                tabkey="name"
                tabTitle="Dimensiones"
              />
            :
              <Box ml={'310px'}>
                <Center h='100px'>
                  <Spinner color='yellow.400' size='xl' />
                </Center> 
              </Box>
            }
            <Stack>
              <Grid templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}>
                <GridItem colSpan={{ md: 1, base: 0 }}>
                </GridItem>
                <GridItem colSpan={1}>
                  <Grid templateColumns={"repeat(3, 1fr)"}>
                    <GridItem colSpan={1} textAlign='end'>
                      <Button onClick={(e)=>{e.preventDefault(); setPage(page - 1)}} disabled={page===0?true:false}>
                        <ArrowBackIcon/>
                      </Button>
                    </GridItem>
                    <GridItem colSpan={1} textAlign='center' alignItems={'center'}>
                      <Text fontSize={'lg'} fontWeight='500'>Página {page + 1}</Text>
                    </GridItem>
                    <GridItem colSpan={1} textAlign='start'>
                      <Button onClick={(e)=>{e.preventDefault(); setPage(page + 1)}} disabled={dataDevs?.length < 10?true:false}>
                        <ArrowForwardIcon />
                      </Button>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem colSpan={{ md: 1, base: 0 }}>
                </GridItem>
              </Grid>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );

}

export default DesarrollosTech;