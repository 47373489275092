import { useRef, useState, useEffect } from "react";
import { Box, IconButton, Text, Button, Flex, Center, Spinner } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  useGetEntityQuery,
  useGetFormsByCategoryQuery,
  usePostEntityMutation,
} from "../../store/boomerangApi";
import { SchemaForm, SchemaTable } from "@grupo-guia/boomerang-schema-ui";
import { EditIcon, SmallAddIcon } from "@chakra-ui/icons";
import { get } from "lodash";
import { actions, selectors } from '../../store/labelSlice';
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { arrangeNestedArray } from "../../utils/nestedArrays";
import { REACT_APP_API_ENDPOINT } from "../../store/apiRoot";

const labelUrl = REACT_APP_API_ENDPOINT + '/entity/get/label';

const codeCategoryForm = "ADM-ZON";
const entity = "label";

const Labels = () => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [editId, setEditId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [tableInfo, setTableInfo] = useState({});
  const [orglabels, setOrglabels] = useState(null);
  const [children, setChildren] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [schemaProperties, setSchemaProperties] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { data, isLoading } = useGetEntityQuery({ entity, include: "label" }); // fetch entity array
  // fetch ui & entity with redux toolkit query
  const { data: dataForms } = useGetFormsByCategoryQuery({
    code: codeCategoryForm,
  });
  const [postEntity] = usePostEntityMutation();
  const draftData = useSelector((state) => selectors.selectById(state, editId));
  const datalabels = useSelector(selectors.selectAll);

  // modal handlers
  const onNew = () => {
    const newData = { id: uuidv4() };
    dispatch(actions.add({ ...newData }));
    setEditId(newData.id);
    onOpen();
  };

  const onSave = async () => {
    try {
      const response = await postEntity({ entity, ...draftData });
      dispatch(actions.update(response.data));
      setEditId(null);
    } catch (error) {
      //console.log("error:", error);
    }
    onClose();
  };

  useEffect(() => {
    if (dataForms) {
      const [objTable, objSchema] = dataForms;
      setTableInfo(objTable || {});
      if (objSchema) {
        let _objSchema = {...objSchema, properties: {...objSchema.properties, label_id: label_id }};
        setSchemaProperties(_objSchema || {});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForms]);

  useEffect(() => {
    if (tableInfo?.properties) {
      const fieldsToShow = get(tableInfo, "properties.fieldsToShow", []);
      const fieldLabels = get(tableInfo, "properties.fieldLabels", []);
      const mapped = fieldsToShow.map((field, i) => {
        return { key: field, text: fieldLabels[i] || field };
      });
      setColumns(mapped.concat([{ key: "actions", text: "Acciones" }]));
    }
  }, [tableInfo, tableInfo?.properties]);

  // when dataEntity changes
  useEffect(() => {
    if (editId) {
      const tmp = data.find((item) => item.id === editId);
      if (tmp) {
        dispatch(actions.add(tmp));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      dispatch(actions.setAll(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (datalabels && datalabels.length >0){
      const olabels = arrangeNestedArray(datalabels, 'label', 'label_id');
      //console.log('labels:', olabels);
      setOrglabels( olabels );
    }
  }, [datalabels]);

  function makeChindren( array, nestedProperty ) {
    const result = {
      key: 'label',
      label: 'Etiquetas',
      tabkey: 'name',
      table: {
        header: HeaderTable,
        columns: [
          {key:'code', text: 'clave'},
          {key:'name', text:'Nombre'},
          {key:'actions', text:'Acciones'},
        ],
        customRows: [
          { key: "actions", component: EditComponent },
        ]
      }
    }
    array.forEach( item => {
      if (item.hasOwnProperty(nestedProperty)) {
        result.children = [];
        result.children.push( makeChindren( item[nestedProperty], nestedProperty ) );
      }
    });
    return result;
  }

  useEffect(() => {
    if (orglabels) {
      //console.log('datalabels:', datalabels );
      const tmpChildren = makeChindren( orglabels, 'label' );
      setChildren( tmpChildren );
      console.log('children:', tmpChildren );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orglabels]);

  const HeaderTable = (props) => {
    return (
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          rightIcon={<SmallAddIcon />}
          colorScheme="teal"
          onClick={onNew}
          ml="auto"
        >
          Nuevo
        </Button>
      </Flex>
    );
  };

  const EditComponent = ({ dataRow }) => {
    return (
      <IconButton
        colorScheme="blue"
        aria-label="Editar fila"
        icon={<EditIcon />}
        onClick={() => {
          setEditId(dataRow.id);
          onOpen();
        }}
      />
    );
  };

  const formHandler = (key, inputValue) => {
    dispatch(actions.update({ id: editId, [key]: inputValue }));
  };

  const getSelectedData = (dataSelected) => {
    //console.log(dataSelected);
    let el = document.getElementById('label_id')
    el.value = dataSelected
    el.disabled = true
    setSelectedLabel(dataSelected)
  };  

  // Arma el campo SchemaChakraSelect
  const state = JSON.parse(localStorage.getItem("reduxState"));
  const headers = {};
  if (state.user && state.user.token) {
    headers.Authorization = `Bearer ${state.user.token}`;
  }
  const label_id = {
    title: 'Etiqueta a la que pertenece',
    type: 'string',
    enum: datalabels?[...datalabels]:[],
    widget: {
      type: 'SchemaChakraCustomSelect',
      formControl: {
        isRequired: true,
      },
      props: {
        rounded: 'full',
      },
      selectProps: {
        minimumInputLength: 3,
        id: "id",
        name: "name",
        filterKey: 'name',
        isMultiple: false,
        selectionHandler: getSelectedData,
        inputPlaceholder: 'Buscar...',
        placeholder: 'Etiqueta ...'
      },
      ajax: {
        headers,
        url: labelUrl,
        type: 'POST',
        requestParams: function (params) {
          //console.log('params:', params);
          // con este método podemos generar la query o enviar los parámetros que necesitamos al backend.
          const labelParams = { query: { select: { id: true, name: true }, where: { name: { contains: params.toUpperCase() }}, orderBy: { full_name: "asc" } }}
          return {
            q: labelParams.query,
          };
        },
        processResults: function (data) {
          // recordemos que select2 muestra id y text, por lo tanto con esto podemos mapear el resultado de la petición y mapear correctamente y retornar esa colección al componente para que muestre el listado.
          // Este método ayuda también para el caso si usas templateResult...
          console.log("ajax result:", data);
          const mapped = data.map((el) => {
            return {
              id: el.id,
              text: el.name,
            };
          });
          return mapped;
        },
      },
    },
  };  

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Etiqueta</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SchemaForm
              schema={schemaProperties}
              customModelSetter={formHandler}
              customModelValues={editId ? draftData : {}}
              classNames={{
                templateColumns: { md: "repeat(2, 1fr)", base: "1fr" },
                gap: { md: 3, base: 2 },
                mt: "2",
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onSave}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box max="6xl" mx="auto" mt="2" pt={0} pb={0}>
        {isLoading === true && (
          <Center h='100px'>
            <Spinner color='yellow.400' size='lg' />
          </Center>
        )}
        {isLoading === false && children && (
          <Box bg="white" p="2">
            {tableInfo.properties ?
              <SchemaTable
                headerTable={HeaderTable}
                tabTitle="Etiquetas"
                title="Catálogo de etiquetas"
                columns={columns}
                data={orglabels}
                Rowactions={EditComponent}
                tabkey="name"
                isSelectable
                treeChildren={children}
              />
              :
              <Box>
                <Center h='100px'>
                  <Spinner color='yellow.400' size='xl' />
                </Center> 
              </Box>
            }
          </Box>
        )}
      </Box>
    </>
  );
};

export default Labels;