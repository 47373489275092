import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    600: "#EDBE00",
    500: "#FFCD00",
  },
  accent: {
    600: "#05253A",
    500: "#002B49",
  },
  bg: {
    600: "#F4F4F4",
    500: "#E5E5E5",
  }
};

const fonts = {
  heading: "Montserrat, sans-serif",
  body: "Montserrat, sans-serif",
};

const styles = {
  global: {
    a: {
      color: "#000",
    },
  },
};

const shadows = {
  outline: "0 0 0 3px rgba(255,205,0, 0.6)",
};

const lightTheme = extendTheme({ colors, styles, fonts, shadows });

export default lightTheme;
