import { Flex, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PickList } from "primereact/picklist";
import { filter, map } from "lodash";


const PriceListDefs = (props) => {
  const { data, property_model, property_group, property, setData } = props;

  const [type, setType] = useState(data?.type || "GLOBAL");
  const [property_models, setPropertyModels] = useState(data?.property_models || []);
  const [property_groups, setPropertyGroups] = useState(data?.property_groups || []);
  const [properties, setProperties] = useState(data?.properties || []);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);

  // cuando se cambia el tipo actualiza la listas (source y target)
  useEffect(() => {
    switch (type) {
      case "GLOBAL":
        setSource([]);
        setTarget(["*"]);
        break;
      case "GROUP":
        setSource( 
          property_groups.length > 0
          ? filter( property_group, (item) => !property_groups.includes(item.id) )
          : property_group 
        );
        setTarget( filter( property_group, (item) => property_groups.includes(item.id) ) );
        break;
      case "MODEL":
        setSource( 
          property_models.length > 0
          ? filter( property_model, (item) => !property_models.includes(item.id) )
          : property_model
        );
        setTarget( filter( property_model, (item) => property_models.includes(item.id) ) );
        break;        
      case "PROPERTY":
        setSource( 
          properties.length > 0
          ? map( property, (item) => !properties.includes(item.id) )
          : property
        );
        setTarget( filter( property, (item) => properties.includes(item.id) ) );
        break;

      default:
        setSource([]);
        setTarget(["*"]);
        break;
    }
    setData({
      ...data,
      property_models: type === "GLOBAL" ? [] : property_models,
      property_groups: type === "GLOBAL" ? [] : property_groups,
      properties: type === "GLOBAL" ? ["*"] : properties,
    });    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[type]);

  const itemTemplate = (item) => {
    return (
      <Flex 
        wrap={"wrap"}
        align={"center"}
        justifyContent={"space-around"}
        direction={"row"}
        gap={4}
        p={4}
      >
        {item.dimension_name && <Text fontSize="md" fontWeight="bold">{item.dimension_name}:</Text>}
        <Text fontSize="md" fontWeight="bold">{item.name?.substring(item.name?.indexOf('-')+1)}</Text>
        {item.operation_props?.presale?.price && <Text fontSize="md" fontWeight="bold">${item.operation_props.presale.price}:</Text>}
      </Flex>
    )
  }

  const onSelectedChange = (e) => {
    //console.log('onSelectedChange: ', e);
    setSource(e.source);
    setTarget(e.target);
    switch (type) {
      case "GLOBAL":
        setProperties(["*"]);
        break;
      case "GROUP":
        setPropertyGroups( map( e.target, 'id' ) );
        break;
      case "MODEL":
        setPropertyModels( map( e.target, 'id' ) );
        break;
      case "PROPERTY":
        setProperties( map( e.target, 'id' ) );
        break;
      default:
        break;
    }
    setData({
      ...data,
      property_models: type === "MODEL" ? map( e.target, 'id' ) : type === "GLOBAL" ? [] : property_models,
      property_groups: type === "GROUP" ? map( e.target, 'id' ) : type === "GLOBAL" ? [] : property_groups,
      properties: type === "PROPERTY" ? map( e.target, 'id' ) : type === "GLOBAL" ? ["*"] : properties,
    });
  }

  return (
    <Flex direction="column" p={4}>
      <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
        <Text fontSize="xl" fontWeight="bold">Tipo</Text>
        <RadioGroup onChange={setType} value={type}>
          <Flex direction="row" justifyContent={"space-around"}>
            <Radio value="GLOBAL" size={"lg"}>Global (todos)</Radio>
            <Radio value="GROUP" size={"lg"}>Dimension</Radio>
            <Radio value="MODEL" size={"lg"}>Modelos</Radio>
            <Radio value="PROPERTY" size={"lg"}>Unidades Privativas</Radio>
          </Flex>
        </RadioGroup>
      </Stack>
      { type !== "GLOBAL" && (
        <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"}>
          <Text fontSize="xl" fontWeight="bold">
            { type === "GROUP" ? "Dimensiones" : type === "MODEL" ? "Modelos" : "Unidades Privativas" }
          </Text>
          <PickList
            sourceStyle={{ height: '30rem' }} targetStyle={{ height: '30rem' }}
            source={source} target={target} onChange={onSelectedChange}
            sourceHeader="Disponibles" targetHeader="Seleccionados"
            filter filterBy="name" sourceFilterPlaceholder="Buscar por nombre"
            itemTemplate={itemTemplate}
          />
        </Stack>
      )}
    </Flex>
  )
}

export default PriceListDefs;