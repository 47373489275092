import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import Zones from "./pages/Catalogos/Zones";
import Labels from "./pages/Catalogos/Labels";

// Nando shit
import Login from './pages/Login'
import Desarrollos from "./pages/Catalogos/Desarrollos";
import DesarrollosTech from "./pages/Catalogos/DesarrollosTech";
import PolicyTypes from "./pages/Catalogos/PoliticasPrecios";
import DesarrollosFin from "./pages/Catalogos/DesarrollosFin";
import PriceLists from "./pages/Catalogos/PriceLists";
//import Propiedades from "./pages/Catalogos/Propiedades";
const Companias = lazy(() => import("./pages/Catalogos/Companias"));
const Programas = lazy(() => import("./pages/Catalogos/Programas"));
const Diccionarios = lazy(() => import("./pages/Catalogos/Diccionarios"));
const Proyectos = lazy(() => import("./pages/Catalogos/Proyectos"));
const Dashboard = lazy(() => import("./layouts/Dashboard"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Datos = lazy(() => import("./pages/Catalogos/Datos"));
const Controles = lazy(() => import("./pages/Catalogos/Controles"));
const Contactos = lazy(() => import("./pages/Catalogos/Contactos"));
const Propiedades = lazy(() => import("./pages/Catalogos/Propiedades"));
const LogEvents = lazy(() => import("./pages/Catalogos/LogEvents"));

//const Login = lazy(() => import("./pages/Login"));

const SwitchRouter = () => {
  return (
    <Suspense
      fallback={
        <Flex
          minH="100vh"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
          Cargando...
        </Flex>
      }
    >
      <Routes>
        <Route
          path="/dashboard"
          element={
            <Sidebar>
              <Dashboard />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/programas"
          element={
            <Sidebar>
              <Programas />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/zonas"
          element={
            <Sidebar>
              <Zones />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/etiquetas"
          element={
            <Sidebar>
              <Labels />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/diccionario"
          element={
            <Sidebar>
              <Diccionarios />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/companias"
          element={
            <Sidebar>
              <Companias />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/proyectos"
          element={
            <Sidebar>
              <Proyectos />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/tipos-datos"
          element={
            <Sidebar>
              <Datos />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/controles"
          element={
            <Sidebar>
              <Controles />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/contactos"
          element={
            <Sidebar>
              <Contactos />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/propiedades"
          element={
            <Sidebar>
              <Propiedades />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/desarrollos"
          element={
            <Sidebar>
              <Desarrollos />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/desarrollosTech"
          element={
            <Sidebar>
              <DesarrollosTech />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/desarrollosFin"
          element={
            <Sidebar>
              <DesarrollosFin />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/log-events"
          element={
            <Sidebar>
              <LogEvents />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/politicasPrecios"
          element={
            <Sidebar>
              <PolicyTypes />
            </Sidebar>
          }
        />
        <Route
          path="/catalogos/listas-precio"
          element={
            <Sidebar>
              <PriceLists />
            </Sidebar>
          }
        />
        <Route path="/" element={<Login />} />
      </Routes>
    </Suspense>
  );
};

const Router = () => {
  return (
    <SwitchRouter />
  );
};

export default Router;
