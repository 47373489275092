import { InputText } from "primereact/inputtext";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chips } from "primereact/chips";

const DimensionList = (props) => {
  const { data, setData, masterId } = props;
  const [dimensions, setDimensions] = useState([]);

  useEffect(() => {
    setDimensions(data);
    //console.log('DimList.setDimensions:', data);
  }, [data]);

  useEffect(() => {
    if (dimensions && (Array.isArray(dimensions)) && (dimensions.length > 0)) {
      //console.log('DimList.setData:', dimensions);
      setData(masterId, dimensions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const chipsEditor = (options) => {
    return <Chips value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
  }

  const itemsBodyTemplate = (rowData) => {
    return ( Array.isArray(rowData.items)) 
      ? rowData.items.join(' | ') : '';
  } 

  /*
  const actionsBodyTemplate = (rowData) => {
    return (
      <>
        <ButtonPrime icon="pi pi-arrow-up" className="p-button-rounded mr-2" onClick={() => upDimension(rowData)} />
        <ButtonPrime icon="pi pi-arrow-down" className="p-button-rounded mr-2" onClick={() => downDimension(rowData)} />
        <ButtonPrime icon="pi pi-arrow-edit" className="p-button-rounded mr-2" onClick={() => editDimension(rowData)} />
      </>
    );
  }
  */

  const onRowEditComplete = (e) => {
    let _dimensions = [...dimensions];
    let { newData, index } = e;
    console.log('items:', newData.items);
    const _newData = {
      dimension_id: newData.dimension_id, 
      name: newData.name, 
      order: newData.order,
      limit: newData.items.length,
      items: newData.items,
      pre: newData.pre,
    }
    _dimensions[index] = _newData;
    //console.log('DimList.setDimensions2:', _dimensions);
    setDimensions(_dimensions);
  }

  const onNewRow = () => {
    const newData = {
      dimension_id: uuidv4(),
      name: `dimensión ${dimensions.length+1}`,
      order: dimensions.length+1,
      limit: 0,
      items: [],
      pre: "",
    }
    //console.log('DimList.setDimensions3:', [...dimensions, newData]);
    setDimensions([...dimensions, newData]);
  }

  return (
    <Flex direction="column">
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          mb="6"
          rightIcon={<AddIcon />}
          colorScheme="teal"
          onClick={onNewRow}
          ml="auto">
          Nuevo
        </Button>
      </Flex>
      <DataTable 
        id={`tableDim${masterId}`}
        //showGridlines 
        value={dimensions} 
        editMode="row"
        dataKey="dimension_id" 
        onRowEditComplete={onRowEditComplete} 
        responsiveLayout="scroll" 
      >
        <Column
          field="name" 
          header="Nombre (Sección/Coto/Torre/Piso)" 
          editor={(options) => textEditor(options)} 
          style={{ width: "15%"}}
        />
        <Column
          field="items" 
          header="Elementos en ésta dimensión" 
          editor={(options) => chipsEditor(options)}
          body={itemsBodyTemplate}
          style={{ width: "70%"}}
        />
        <Column
          field="pre" 
          header="Prefijo dinámico" 
          editor={(options) => textEditor(options)} 
          style={{ width: "5%"}}
        />
        <Column 
          rowEditor 
          header="Acciones" 
          headerStyle={{ width: '10%', minWidth: '8rem' }} 
          bodyStyle={{ textAlign: 'center' }}
        />
      </DataTable>
    </Flex>
  );
}

export default DimensionList;
