import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Input, Radio, RadioGroup, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { find } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const DiscountList = (props) => {
  const { data, setData, price_lists } = props;
  const [discounts, setDiscounts] = useState(data || []);
  const statuses = ["ACTIVE", "INACTIVE"];
  const types = [ {value: "PERMANENT", label: "Permanente"}, {value: "TEMPORARY", label: "Temporal"}];

  useEffect(() => {
    if (Array.isArray(discounts) && discounts.length > 0) {
      setData(discounts);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discounts]);

  const onNewRow = () => {
    const newData = {
      id: uuidv4(),
      name: `Descuento ${discounts.length+1}`,
      type: 'PERMANENT',
      status: 'ACTIVE',
      percentage: 0,
      price_lists: "*",
      start: null,
      end: null,
    }
    setDiscounts([...discounts, newData]);
  }

  const onRowEditComplete = (e) => {
    let _discounts = [...discounts];
    let { newData, index } = e;
    const _newData = { ...newData };
    _discounts[index] = _newData;
    setDiscounts(_discounts);
  }

  const onChangePlanPriceLists = (index, value ) => {
    // establece las listas de precio que aplican al descuento
    let _discounts = [...discounts];
    let _discount = {..._discounts[index], price_lists: value };
    _discounts[index] = _discount;
    setDiscounts(_discounts);
  }

  const onChangeStart = (index, value) => {
    let _discounts = [...discounts];
    let _discount = {..._discounts[index], start: value };
    _discounts[index] = _discount;
    setDiscounts(_discounts);
  } 

  const onChangeEnd = (index, value) => {
    let _discounts = [...discounts];
    let _discount = {..._discounts[index], end: value };
    _discounts[index] = _discount;
    setDiscounts(_discounts);
  }

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Seleccionar Status"
      />
    );
  };  

  const typeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={types}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Seleccionar Tipo"
      />
    );
  };  

  const percentageEditor = (options) => {
    return (
      <Input 
        type="number" 
        value={options.value} 
        onChange={(e) => options.editorCallback(e.target.value)} 
        htmlSize={4} width='100px' size='lg' 
        max={100} min={0}
      />
    );
  }

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={rowData.status === 'ACTIVE' ? "success" : "danger"}></Tag>;
  };

  const percentageBodyTemplate = (rowData) => {
    return <Text textAlign={"center"}>{ `${rowData.percentage}` }%</Text>;
  }

  const typeBodyTemplate = (rowData, policyType) => {
    return <div>{ 
      find( types, { value: rowData.type})?.label }</div>;
  }

  return (
    <Flex direction="column">
      <Tabs variant={"enclosed"}>
        <TabList>
          <Tab>Descuentos</Tab>
          { discounts.length > 0 && discounts.map((plan, index) => (
            <Tab key={plan.id}>{plan.name}</Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
              <Button
                mb="6"
                rightIcon={<AddIcon />}
                colorScheme="teal"
                onClick={onNewRow}
                ml="auto">
                Nuevo
              </Button>
            </Flex>
            <DataTable 
              key={`tableDiscounts`}
              id={`tableDiscounts`}
              //showGridlines 
              value={discounts} 
              editMode="row"
              dataKey="id" 
              onRowEditComplete={onRowEditComplete} 
              responsiveLayout="scroll" 
            >
              <Column
                field="name" 
                header="Nombre" 
                editor={(options) => textEditor(options)} 
                style={{ width: "20%"}}
              />
              <Column
                field="percentage" 
                header="Descuento %" 
                editor={(options) => percentageEditor(options)}
                body={percentageBodyTemplate}
                style={{ width: "20%"}}
              />
              <Column
                field="type" 
                header="Tipo" 
                editor={(options) => typeEditor(options)}
                body={(options) => typeBodyTemplate(options)}
                style={{ width: "20%"}}
              />
              <Column
                field="status" 
                header="Status" 
                editor={(options) => statusEditor(options)}
                body={statusBodyTemplate}
                style={{ width: "20%"}}
              />
              <Column 
                rowEditor 
                header="Acciones" 
                headerStyle={{ width: '20%', minWidth: '8rem' }} 
                bodyStyle={{ textAlign: 'center' }}
              />
            </DataTable>
          </TabPanel>
          {discounts.length > 0 && discounts.map((discount, index) => (
            <TabPanel key={discount.id}>
              <Flex direction={"column"} p={4}>
                { /* La selección de listas de precio se hace siempre */ }
                <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                  <Text fontSize="xl" fontWeight="bold">Listas de precio</Text>
                  <RadioGroup onChange={(e) => onChangePlanPriceLists(index, e)} value={discounts[index]?.price_lists}>
                    <Flex direction="row" justifyContent={"flex-start"}>
                      <Radio value="*" size={"lg"} mr={4}>Todas</Radio>
                      <Radio value={
                          discounts[index]?.price_lists === '*' ? [] : discounts[index]?.price_lists
                        } size={"lg"} mr={4}>Seleccionar</Radio>
                      { discounts[index]?.price_lists !== "*" && (
                        <MultiSelect
                          display="chip"
                          className="w-full md:w-20rem"
                          value={discounts[index]?.price_lists || []} 
                          onChange={(e) => onChangePlanPriceLists(index, e.value)} 
                          options={price_lists} 
                          optionLabel="name"
                          optionValue="id"
                          placeholder="Seleccionar listas" />
                      )}
                    </Flex>
                  </RadioGroup>
                </Stack>

                { /* si el descuento es tipo permanente se debe mostrar la captura del periodo */ }
                { discount.type === "TEMPORARY" && (
                <Stack border={"1px"} borderRadius={8} p={4} borderColor={"gray.200"} mb={4}>
                  <Text fontSize="xl" fontWeight="bold">Periodo</Text>
                  <Flex direction="row" justifyContent={"flex-start"}>
                    <Text fontSize="xl" mr={4}>Inicio:</Text>
                    <Input 
                      type="date" 
                      value={discount.start || new Date()} 
                      onChange={(e) => onChangeStart(index, e.target.value)} 
                      //htmlSize={4} width='100px' 
                      size='lg' mr={4}
                    />
                    <Text fontSize="xl" mr={4}>Final:</Text>
                    <Input 
                      type="date" 
                      value={discount.end || new Date()} 
                      onChange={(e) => onChangeEnd(index, e.target.value)} 
                      //htmlSize={4} width='100px' 
                      size='lg' mr={4}
                    />
                    </Flex>
                </Stack>
                )}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );  
}

export default DiscountList;