import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";

const company_departmentAdapter = createEntityAdapter({
  selectId: (company_department) => company_department.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const company_departmentSlice = createSlice({
  name: "company_department",
  initialState: company_departmentAdapter.getInitialState(),
  reducers: {
    add: company_departmentAdapter.addOne,
    setAll: company_departmentAdapter.setAll,
    update: company_departmentAdapter.upsertOne,
    removeOne: company_departmentAdapter.removeOne,
    empty: company_departmentAdapter.removeAll,
  },
});

export const { actions } = company_departmentSlice;

export const selectors = company_departmentAdapter.getSelectors((state) => state.company_department);