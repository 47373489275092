import { React, useState, useEffect } from "react";
import {
    Flex,
    Box,
    FormControl,
    Input,
    Stack,
    Link,
    Button,
    Text,
    useColorModeValue,
    Image,
    InputGroup,
    InputRightElement

} from '@chakra-ui/react';

import { loginUser, userSelector, clearState } from '../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { hydrate } from '../store/userSlice';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import logo from '../assets/Logos/logo.webp'
import { useKeycloak } from "@react-keycloak/web";
import { REACT_APP_REDIRECT } from "../store/apiRoot";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { keycloak, initialized } = useKeycloak();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { register, handleSubmit } = useForm();
    // const { isSuccess, isError, errorMessage } = useSelector(
    //     userSelector
    // );


    const onSubmit = (data) => {
        // dispatch(loginUser(data));
    }

    useEffect(() => {
        return () => {
            //   dispatch(clearState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (isError) {
    //         toast({
    //             title: 'login error',
    //             description: errorMessage,
    //             status: 'error',
    //             duration: 5000,
    //             isClosable: true,
    //         });
    // dispatch(clearState());
    //     }

    //     if (isSuccess) {
    //         // dispatch(clearState());
    //         navigate('/dashboard');
    //     }  
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isError, isSuccess, errorMessage]);

    useEffect(() => {
        // console.log(keycloak.authenticated);        
        if (!keycloak.authenticated) {
            
            keycloak.login({
                redirectUri: REACT_APP_REDIRECT
            });
            /*
            keycloak.init({
                onLoad: 'login-required',
                checkLoginIframeInterval: 1,
                redirectUri: 'http://localhost:3000/dashboard'
            })
            
            .then(authenticated => {
                if (authenticated) {
                    console.log('keycloakTokenXXXX:', keycloak.token);
                    const user = keycloak.tokenParsed
                    let userData = {
                        email: user.email,
                        name: user.given_name + " " + user.family_name,
                        token: keycloak.token,
                        refresh_token: keycloak.refreshToken
                    }
                    dispatch(loginUser(userData));
                }
            }).catch(e => {
                console.log("Fail ", e);
            })
            */
        }
    }, [])

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={'bg.600'}
        >
            <Stack spacing={8} mx={'auto'} maxW={{ base: 'sm', lg: 'lg' }} py={12} px={{ base: 0, md: 10 }}>
                <Stack align={'center'} w={{ base: 'sm', lg: 'lg' }}>
                    <Image src={logo} w={24} />
                    {/* <Text pt={2} size={'lg'}>Inicia sesión en <Text fontWeight={600} display='inline-flex'>Boomerang</Text></Text> */}
                </Stack>
                {/* <Box
                    rounded={'xl'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    px={10}
                    py={16}
                    w={{base: 'sm', lg: 'lg' }}
                >
                    <Stack spacing={4}>
                        <form
                        onSubmit={handleSubmit(onSubmit)}
                        method="POST"
                        >
                            <FormControl id="email" mb={6}> 
                                <Input type="email" rounded={'full'} placeholder='Correo eléctronico' py={5} {...register("email")} isRequired/>
                            </FormControl>
                            <FormControl id="password" mb={4}>
                                <InputGroup>
                                    <Input type={showPassword ? 'text' : 'password'} rounded={'full'} placeholder='Contraseña' py={5} {...register("password")} isRequired/>
                                    <InputRightElement h={'full'}>
                                    <Button
                                        bg='transparent'
                                        borderTopRightRadius='full'
                                        borderBottomRightRadius='full'
                                        onClick={() =>
                                        setShowPassword((showPassword) => !showPassword)
                                        }>
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Stack spacing={10}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'start'}
                                    justify={'space-between'} ml={2}>
                                    <Link color={'blue.400'} fontSize='sm' fontWeight={500} href={'https://grupoguia.mx/forgotpassword'} isExternal>¿Olvidaste tu contraseña?</Link>
                                </Stack>
                                <Button
                                    bg={'brand.500'}
                                    color={'white'}
                                    borderRadius='full'
                                    type="submit"
                                    _hover={{
                                    bg: 'brand.600',
                                    }}>
                                    Iniciar sesión
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Box> */}
            </Stack>
        </Flex>
    );
}

export default Login