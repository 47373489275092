import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const developmentAdapter = createEntityAdapter({
  selectId: (development) => development.id,
});

export const developmentSlice = createSlice({
  name: "development",
  initialState: developmentAdapter.getInitialState(),
  reducers: {
    add: developmentAdapter.addOne,
    setAll: developmentAdapter.setAll,
    update: developmentAdapter.upsertOne,
    removeOne: developmentAdapter.removeOne,
    empty: developmentAdapter.removeAll,
    updatePropertyLocationProps(state, action){
      const {id, location_prop} = action.payload;
      const element = JSON.parse(JSON.stringify(state.entities));
      const propty = element[id];
      if (propty) {
          propty.location_props = {...propty.location_props, ...location_prop };
          developmentAdapter.upsertOne(state, propty);
          return;
      }
    },
    updatePropertyJson(state, action){
        const {id, key, value} = action.payload;
        const element = JSON.parse(JSON.stringify(state.entities));
        const ppty = element[id];
        if (ppty) {
            let keys = key.split('__')
            let setter_key = `${keys[0]}`
            if(keys.length > 1){
                keys?.slice(1, keys.length).map((k)=>{
                    setter_key = setter_key + `.${k}`
                })
            }
            const custom_value = set(ppty, setter_key, value);
            developmentAdapter.upsertOne(state, custom_value);
            return;
        }
    }
  }
});

export const { actions } = developmentSlice;

export const selectors = developmentAdapter.getSelectors((state) => state.development);
