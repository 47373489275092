import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { REACT_APP_API_ENDPOINT } from './apiRoot';
//import { Mutex } from 'async-mutex';
//import keycloak from '../keycloak';

//const mutex = new Mutex();
const keycloakTokens = {};

export const tokensCallback = (tokens) => {
  console.log('updated tokens: ', tokens);
  keycloakTokens.token = tokens.token;
}

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_API_ENDPOINT,
  prepareHeaders: async (headers) => {
    /*
    const state = JSON.parse(localStorage.getItem("reduxState"));
    console.log(state)
    console.log("token: ", state.user.token)
    console.log('keycloakToken:', keycloak.token);
    
    if (state.user && state.user.token) {
      headers.set("authorization", `Bearer ${state.user.token}`);
    }
    */
    
    //if (keycloak.authenticated) {
      console.log('authenticated:', keycloakTokens.token);
      headers.set("Authorization", `Bearer ${keycloakTokens.token}`);
    //}
    
    return headers;
  },
})
/*
const BaseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        // try to get a new token
        let state = JSON.parse(localStorage.getItem("reduxState"));
        let userData = state.user;
        const refreshResult = await fetch(
          `${REACT_APP_API_ENDPOINT}/refresh`,
          {
            //mode: 'no-cors',
            method: 'POST',
            headers: {
              Accept: "application/json",
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: new URLSearchParams(userData),
          });
        if (refreshResult.status === 200) {
          const data = await refreshResult.json();
          userData.token = data.token.access_token
          state.user = userData
          // store the new token
          await localStorage.setItem("reduxState", JSON.stringify(state))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          console.log("No se pudo actualizar el token");
          state = {}
          await localStorage.setItem("reduxState", JSON.stringify(state))
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}
*/
export const boomerangApi = createApi({
  reducerPath: 'boomerangApi',
  //baseQuery: BaseQueryWithReauth,
  baseQuery,
  tagTypes:[
    "form", "company"
  ],
  endpoints: (build) => ({
    // consulta una sola form 
    getForm: build.query({
      query: (params) => {
        const url = `/form/${params.code}`;
        //const _params = omit(params, ['code']);
        //const body = { params };
        return { url, method: "GET"}
      },
      providesTags: (result) => 
      result
      ? [...result.map(({ code }) => ({ type: "form", id: code }))]
      : [],
    }),
    // regresar todas las forms que estén ligadas a una categoría
    getFormsByCategory: build.query({
      query: (params) => {
        const url = `/form/byCategory/${params.code}`;
        const _params = omit(params, ['code']);
        //const body = { ...params };
        return { url, method: "GET", params: _params }
      },
      providesTags: (result) => 
      result
        ? [...result.map(({ code }) => ({ type: "form", id: code }))]
        : [],
    }),
    getEntity: build.query({
      query: (params) => {
        const _id = params.id ? `/${params.id}` : '';
        const url = `/entity/${params.entity}` + _id;
        const _params = omit(params, ['entity', 'id']);
        //console.log({ url, method: "GET", params: { query: JSON.stringify( _params )}});
        return { url, method: "GET", params: { query: JSON.stringify( _params )}};
      },
      providesTags: ( result, error, args ) => 
        result
        ? [...result.map(({id}) => ({ type: args.entity, id }))]
        : [],
    }),
    getPostEntity: build.query({
      query: (params) => {
        const url = `/entity/get/${params.entity}`;
        const _params = { query: params.query};
        //console.log({ url, method: "GET", params: { query: JSON.stringify( _params )}})
        return { url, method: "POST", body: _params};
      },
      providesTags: ( result, error, args ) => 
        result
        ? [...result.map(({id}) => ({ type: args.entity, id }))]
        : [],
    }),
    postEntity: build.mutation({
      query: (params) => {
        const url = `/entity/${params.entity}`;
        const _params = omit(params, ['entity', 'changed', 'created']);
        return { url, method: "POST", body: _params }
      },
      //transformResponse: (response ) => response.data,
    }),

    // getView.- La idea aqui es es que funcione como un getEntity mejorado con campos adicionales.
    // se puso el manejo como si fuera un entity pero se pide en modo: v_<ENTITY_NAME>
    // ojo, enviar al post solo los campos del entity y no todos los del view
    getview: build.query({
      query: (params) => {
        const url = `/view/v_${params.entity}`;
        // *** aun no se implementan otros parámetros
        return { url, method: "GET" };
      },
      providesTags: ( result, error, args ) =>
        result
        ? [...result.map(({id}) => ({ type: args.entity, id}))]
        : [],
    }),

    execN8NWebhook: build.mutation({
      query: (params) => {
        const url = `/wfp`;
          // *** params = { "webhook_path": "webhook/syncWiggotDevModelsQRO", ... }
          // *** ver nombre del webhook en n8n
          return { url, method: "POST", body: params }
      }
    }),
  }),
});

export const {
  useGetFormQuery,
  useLazyGetFormQuery,
  useGetFormsByCategoryQuery,
  useLazyGetFormsByCategoryQuery,
  useGetEntityQuery,
  useGetPostEntityQuery,
  useLazyGetEntityQuery,
  usePostEntityMutation,
  useExecN8NWebhookMutation,
  useGetviewQuery,
  useLazyGetviewQuery,
} = boomerangApi;
