import { useRef, useState, useEffect } from "react";
import { Box, Button, Center, Flex, Grid, GridItem, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { actions, selectors } from "../../store/priceListSlice";
import { actions as actionsDev, selectors as selectorsDev } from "../../store/developmentSlice";
import { useGetEntityQuery, useGetFormsByCategoryQuery, usePostEntityMutation, useExecN8NWebhookMutation } from "../../store/boomerangApi";
import { REACT_APP_API_ENDPOINT } from "../../store/apiRoot";
import { SchemaForm, SchemaTable } from "@grupo-guia/boomerang-schema-ui";
import { ArrowBackIcon, ArrowForwardIcon, EditIcon, Search2Icon, SmallAddIcon, StarIcon } from "@chakra-ui/icons";
import { find, findIndex, get, omit } from "lodash";
import PriceListDefs from "../components/priceListDefs";
import PriceListExplosion from "../components/priceListExpl";

const RowStatus = (props) => {
  return (
    <Box
      fontWeight={800}
      px="2"
      rounded="full"
      textAlign="center"
      color="white"
      py="1"
      bg={props.dataColumn === "ACTIVE" || props.dataColumn === "ACTIVO" ? "green.500" : "red.500"}
      fontSize="sm">
      {props.dataColumn}
    </Box>
  );
};

const codeCategoryForm = "TECH-PRLS";
const entity = "price_list";

const PriceLists = () => {

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState(0);
  const [page, setPage] = useState(0);
  const [editId, setEditId] = useState(null);
  const [columns, setColumns] = useState([]);
  //const [tableInfo, setTableInfo] = useState({});
  const [children, setChildren] = useState(null);
  //const [selectedList, setSelectedList] = useState('');
  const [selectedDevelopmentId, setSelectedDevelopmentId] = useState('');
  const [dataPrices, setDataPrices] = useState([]);
  const [operation_props, setOperation_props] = useState({});
  const [schemaPriceList, setSchemaPriceList] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();

  // traer los desarrollos
  const { data: dataDev, isLoading, isSuccess, isError, error } = useGetEntityQuery({entity: "development"});

  // fetch ui & entity with redux toolkit query
  const { data: dataForms } = useGetFormsByCategoryQuery({code: codeCategoryForm});

  // traer el listado de precios con su desarrollo y los detalles
  const { data: dataPrice } = useGetEntityQuery({ entity, include: { price_list_detail : true } });

  // traer los modelos del desarrollo seleccionado
  const { data: dataModel } = useGetEntityQuery({entity: "property_model", where: {development_id: selectedDevelopmentId}});

  // traer las dimensiones (property_group) del desarrollo seleccionado
  const { data: dataPropertyGroup } = useGetEntityQuery({entity: "property_group", where: {development_id: selectedDevelopmentId}});

  // traer las propiedades del desarrollo seleccionado
  const { data: dataProperty } = useGetEntityQuery({entity: "property", where: {development_id: selectedDevelopmentId}});

  const [postEntity] = usePostEntityMutation();
  const [execN8NWebhook] = useExecN8NWebhookMutation();

  const draftData = useSelector((state) => selectors.selectById(state, editId));
  const devData = useSelector(selectorsDev.selectAll);
  const priceData = useSelector(selectors.selectAll);

  // organizar los datos de precios, para que muestre los desarrollos
  // y en un segundo nivel las listas de precios ( activa e inactivas )
  // en el tercer nivel esta el detalle de lista: unidades privativas (property_id)
  const orgData = () => {
    try {
      const org = [];
      if (dataDev && dataDev.length > 0) {
        // agrega los desarrollos y el array price_list a cada uno
        for (let i=0; i< dataDev.length; i++) {
          org.push({ price_list: [], ...dataDev[i]});
        }
        if (Array.isArray(dataPrice) && dataPrice.length > 0) {
          // agrega las listas y el array price_list_detail a cada uno
          for (const list of dataPrice) {
            for (let i=0; i < org.length; i++) {
              if (org[i].id === list.development_id) {
                const detalles = [];
                for (let d=0; d<list.price_list_detail.length; d++) {
                  const propName = find(
                    dataProperty,
                    { id: list.price_list_detail[d].property_id },
                  )?.description;
                  detalles.push({ 
                    property: propName?.substring(propName?.indexOf('-')+1),
                    ...list.price_list_detail[d],
                  });
                }
              org[i].price_list.push({...list, price_list_detail: detalles});
              }
            }
          }
        }
      }
      //console.log('data: ', org);
      setDataPrices(org);
    } catch (error) {
      console.log('error:', error);
    }
  }

  // arma la estructuera que define las ciuadriculas
  const orgColumns = () => {
    if (dataForms && dataForms.length > 0) {

      // arma las columnas para la tabla principal
      const tableInfo = find(dataForms, { 'type': 'table', 'entity': 'development'});
      const listInfo = find(dataForms, { 'type': 'table', 'entity': 'price_list'});
      const detailInfo = find(dataForms, { 'type': 'table', 'entity': 'price_list_detail'});
      const schPriceList = find(dataForms, { 'type': 'form', 'entity': 'price_list'});
      if (schPriceList)
        setSchemaPriceList(schPriceList);
      if (tableInfo?.properties) {
        const fieldsToShow = get(tableInfo, "properties.fieldsToShow", []);
        const fieldLabels = get(tableInfo, "properties.fieldLabels", []);
        const mapped = fieldsToShow.map((field, i) => {
          return { key: field, text: fieldLabels[i] || field };
        });
        setColumns(mapped.concat([{ key: "actions" }]));
        //console.log('columns:', mapped.concat([{ key: "actions" }]));
      }

      const objMapped = dataForms.reduce((obj, el) => {
        if (!obj[el.entity]) {
          obj[el.entity] = {};
        }
        let mapped = el;
        if (el.type === "table") {
          const fieldsToShow = get(el, "properties.fieldsToShow", []);
          const fieldLabels = get(el, "properties.fieldLabels", []);
          mapped = fieldsToShow.map((field, i) => {
            return { key: field, text: fieldLabels[i] || field };
          });
          mapped = mapped.concat([{ key: "actions", text: "Acciones" }]);
        }
        //if(el.type === "form" && el.entity === 'contacts_props'){
        //  mapped = {...el, properties: {...el.properties, contact_id: employee_id }};
        //}

        obj[el.entity][el.type] = mapped;
        return obj;
      }, {});

      // arma la estructura de las tablas interiores
      const child = {
        key: "price_list",
        label: "Listas de precio",
        tabkey: "code",
        table: {
          header: HeaderTable,
          columns: objMapped?.price_list?.table || [],
          customRows: [
            { key: "status", component: RowStatus },
            { key: "actions", component: EditComponent },
          ],
        },
        children: [
          {
            key: "price_list_detail",
            label: "Unidades",
            tabkey: "list_number",
            table: {
              //header: HeaderTable,
              columns: objMapped?.price_list_detail?.table || [],
              customRows: [
                //{ key: "status", component: RowStatus },
                //{ key: "actions", component: (<></>) },
              ],
            },
          }
        ],
      };
      //console.log('children:', child);
      setChildren(child);
    }
  }

  // data arrange
  useEffect(() => {
    if (Array.isArray(dataPrice) && dataPrice.length > 0) {
      dispatch(actions.setAll(dataPrice));
      orgData();
    }
    if (dataForms && !children) {
      orgColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrice,dataDev,dataForms,dataProperty]);

  useEffect(() => {
    if (Array.isArray(dataDev) && dataDev.length > 0) {
      dispatch(actionsDev.setAll(dataDev));
      orgData();
    }
    if (dataForms && !children) {
      orgColumns();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrice,dataDev,dataForms,dataProperty]);

  useEffect(() => {
    if (Array.isArray(priceData) && priceData.length > 0 && 
        Array.isArray(devData) && devData.length > 0) {
          orgData();
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devData,priceData,dataProperty]);

  useEffect(() => {
    // si cambian las operation_props debo actualizar el state
    if (operation_props) {
      priceListFormHandler('operation_props', operation_props);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation_props]);

  useEffect(() => {
    if (isError && error) {
      console.log('error:', error);
    }
  }, [isError, error]);
/*
  useState(() => {
    if (dataForms) {
      console.log('dataForms:', dataForms);
      //const [objTable, objSchemaGeneral, objSchemaLocationProps] = dataForms;
      //setTableInfo(find(dataForms, { 'type': 'table', 'entity': 'development'}));
      //setSchemaDevelopment(objSchemaGeneral || {});
      //setSchemaPriceListProps(objSchemaLocationProps || {})
      orgColumns(find(dataForms, { 'type': 'table', 'entity': 'development'}));
    }
  }, [dataForms]);
*/
  // modal handlers
  const handleSearch = () => {
    setSearch(inputValue)
  }

  const priceListFormHandler = (key, inputValue) => {
    //console.log(
      dispatch(actions.updatePropertyJson({ id: editId, key: key,  value: inputValue }))
    //);
  };

  const onNew = (props) => {
    //console.log('newProps:', props);
    const newData = { id: uuidv4(), development: { connect: { id: props?.entity?.id } } };
    dispatch(actions.add({ ...newData }));
    setEditId(newData.id);
    onOpen();
  };

  const onExplode = async (props) => {
    try {
      // si no hay price_list_detail se mandara a ejecutar despues del guardado
      console.log('props:', props);
      const doExplPriceListDetail = 
      props?.price_list_detail 
        && props?.price_list_detail.length < 1 
        && props?.operation_props?.explosion 
        && props?.operation_props?.explosion.length > 0;
      
      // en caso necesario manda a ejecutar la explision de la lista detalle
      if (doExplPriceListDetail) {
        const response = await execN8NWebhook(
          { 
            webhook_path: "webhook/explosion_price_list_detail",
            price_list_id: props.id,
            explosion: props?.operation_props?.explosion,
          }
          );
          console.log('response2:', response);
          toast({
            title: "Explosion de la lista de precios detalle",
            description: response?.data,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }

      //alert(doExplPriceListDetail);    
    } catch (error) {
      console.log("error:", error);
      toast({
          title: 'Explosion error',
          description: error?.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
      });
    }
  }

  const onSave = async () => {
    try {
      const _draftData = {
        ...draftData,
        development: { connect: { id: draftData.development_id } },
      }

      // guarda la edición de la lista de precios
      const response = await postEntity({ entity, ...omit( _draftData , ["development_id", "price_list_detail"]) });
      dispatch(actions.update(response.data));
        
      setEditId(null);
    } catch (error) {
      console.log("error:", error);
      toast({
          title: 'Explosion error',
          description: error?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
      });
    }
    onClose();
  };

  const setModalOperationProps = (data) => {
    setOperation_props(data);
    //console.log('operation_props:', data);
  }

  // table components
  const HeaderTable = (props) => {
    return (
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          rightIcon={<SmallAddIcon />}
          colorScheme="teal"
          onClick={ () => {onNew(props); }}
          ml="auto">
          Nuevo
        </Button>
      </Flex>
    );
  };

  const EditComponent = ({ dataRow }) => {
    return (
      <Stack direction="row" alignItems="center" p={4}>
        <Tooltip label="Editar fila" aria-label="Editar fila">
          <IconButton
            colorScheme="blue"
            aria-label="Editar fila"
            icon={<EditIcon />}
            onClick={() => {
              onEdit(dataRow);
            }}
          />
        </Tooltip>
        <Tooltip 
          label="Explosion de la lista de precios detalle" 
          aria-label="Explosion de la lista de precios detalle">
          <IconButton
            colorScheme="blue"
            aria-label="Explosion de la lista de precios detalle"
            icon={<StarIcon />}
            disabled={dataRow?.price_list_detail?.length > 0}
            onClick={() => {
              onExplode(dataRow);
            }}
          />
        </Tooltip>
      </Stack>
    );
  };

  // table events
  const onRowSelected = (props) => {
    // toma la lista seleccionada para actualizar el detalle de lista
    // la identifico dentro de los datos anidados por que es el que tiene 
    // un campo llamado development_id
    //console.log('props: ', props);
    if (props?.rowInfo?.company_id && props?.rowInfo?.id) {
      console.log('selectedDevelopmentId: ', props?.rowInfo?.id );
      setSelectedDevelopmentId(props?.rowInfo?.id);
    }
  }

  const onEdit = (props) => {
    setEditId(props?.id);
    onOpen();    
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lista de precios</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={onSave}>
            <ModalBody pb={6}>
            <Tabs variant='enclosed'>
              <TabList>
                  <Tab>Datos Generales</Tab>
                  <Tab>Definir alcance</Tab>
                  <Tab>Explosion de propiedades</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  { /* Formulario de datos generales */}
                  <SchemaForm
                      schema={schemaPriceList}
                      customModelSetter={priceListFormHandler}
                      customModelValues={editId && draftData ? draftData : {}}
                      classNames={{
                      templateColumns: { md: "repeat(2, 1fr)", base: "1fr" },
                      gap: { md: 3, base: 2 },
                      mt: "2",
                      }}
                  />
                </TabPanel>
                <TabPanel>
                  { /* Formulario de alcance */}
                  <PriceListDefs 
                    //data={draftData?.operation_props || {}}
                    data={draftData?.operation_props || operation_props}
                    setData={setModalOperationProps}
                    property_model={dataModel}
                    property_group={dataPropertyGroup}
                    property={dataProperty}
                  />
                </TabPanel>
                <TabPanel>
                  { /* vista previa de explosion de propiedades */}
                  <PriceListExplosion
                    //data={draftData?.operation_props || {}}
                    data={draftData?.operation_props || operation_props}
                    setData={setModalOperationProps}
                    property_model={dataModel}
                    property_group={dataPropertyGroup}
                    property={dataProperty}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit" onClick={onSave}>
                    Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>      
      <Box max="6xl" mx="auto" mt="2" pt={0} pb={0}>
        {dataPrices.length === 0 && (
          <Center h='100px'>
            <Spinner color='yellow.400' size='lg' />
          </Center>
        )}
        {dataPrices.length > 0 && (
          <Box bg="white" p="2">
            <Stack float={'right'} py={4}>
              <Grid templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}>
                <GridItem colSpan={2}>
                  <Input onChange={(e)=>{e.preventDefault(); setInputValue(e.target.value)}}/>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button onClick={(e)=>{e.preventDefault(); handleSearch();}}>
                    <Search2Icon />
                  </Button>
                </GridItem>
              </Grid>
            </Stack>
            { (dataForms?.length > 0) && (columns?.length>0) ?
              <SchemaTable
                //headerTable={HeaderTable}
                tabTitle="Desarrollos"
                title="Seleccione un desarrollo para ver las listas que contiene"
                columns={columns}
                data={dataPrices}
                Rowstatus={RowStatus}
                //Rowactions={EditComponent}
                tabkey="code"
                isSelectable
                treeChildren={children}
                onRowSelected={onRowSelected}
              />
            :
              <Box ml={'310px'}>
                <Center h='100px'>
                  <Spinner color='yellow.400' size='xl' />
                </Center> 
              </Box>
            }
            <Stack>
              <Grid templateColumns={{ md: "repeat(3, 1fr)", base: "1fr" }}>
                <GridItem colSpan={{ md: 1, base: 0 }}>
                </GridItem>
                <GridItem colSpan={1}>
                  <Grid templateColumns={"repeat(3, 1fr)"}>
                    <GridItem colSpan={1} textAlign='end'>
                      <Button onClick={(e)=>{e.preventDefault(); setPage(page - 1)}} disabled={page===0?true:false}>
                        <ArrowBackIcon/>
                      </Button>
                    </GridItem>
                    <GridItem colSpan={1} textAlign='center' alignItems={'center'}>
                      <Text fontSize={'lg'} fontWeight='500'>Página {page + 1}</Text>
                    </GridItem>
                    <GridItem colSpan={1} textAlign='start'>
                      <Button onClick={(e)=>{e.preventDefault(); setPage(page + 1)}} disabled={dataPrices?.length < 10?true:false}>
                        <ArrowForwardIcon />
                      </Button>
                    </GridItem>
                  </Grid>
                </GridItem>
                <GridItem colSpan={{ md: 1, base: 0 }}>
                </GridItem>
              </Grid>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  )
}

export default PriceLists;