import { createSlice } from "@reduxjs/toolkit";

export const localStorageSlice = createSlice({
    name: "local_storage",
    initialState: {
        countries: JSON.parse(localStorage.getItem('dictionaryForm'))
    },
    reducers: {
        saveCountries(state, action) {
            console.log(action.payload);
            localStorage.setItem('dictionaryForm', JSON.stringify(action.payload));
            state.countries = action.payload
        }
    }
});

export const { loadCountries, saveCountries } = localStorageSlice.actions;

export default localStorageSlice.reducer;