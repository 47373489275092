import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const controlTypeAdapter = createEntityAdapter({
    selectId: (controlType) => controlType.id
});

export const controlTypeSlice = createSlice({
        name: "control_type",
        initialState: controlTypeAdapter.getInitialState(),
        reducers: {
            add: controlTypeAdapter.addOne,
            setAll: controlTypeAdapter.setAll,
            update: controlTypeAdapter.upsertOne,
            removeOne: controlTypeAdapter.removeOne,
            empty: controlTypeAdapter.removeAll,
        },
    });
  
export const { actions } = controlTypeSlice;
  
export const selectors = controlTypeAdapter.getSelectors((state) => state.controlType);