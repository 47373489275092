import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const draftAdapter = createEntityAdapter({
  selectId: (draft) => draft.id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const draftSlice = createSlice({
  name: "draft",
  initialState: draftAdapter.getInitialState(),
  reducers: {
    add: draftAdapter.addOne,
    setAll: draftAdapter.setAll,
    update: draftAdapter.upsertOne,
    delete: draftAdapter.removeOne,
    empty: draftAdapter.removeAll,
    updatePropertyJson(state, action){
      const {id, key, value} = action.payload;
      const element = JSON.parse(JSON.stringify(state.entities));
      const ppty = element[id];
      if (ppty) {
          let keys = key.split('__')
          let setter_key = `${keys[0]}`
          if(keys.length > 1){
              keys?.slice(1, keys.length).map((k)=>{
                  setter_key = setter_key + `.${k}`
              })
          }
          const custom_value = set(ppty, setter_key, value);
          draftAdapter.upsertOne(state, custom_value);
          return;
      }
  }
  }
});

export const { actions } = draftSlice;

export const selectors = draftAdapter.getSelectors( state => state.draft );
