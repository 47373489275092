import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
// import { cloneDeep, remove } from "lodash";
import { set } from "lodash";

const property_mediaAdapter = createEntityAdapter({
  selectId: (property_media) => property_media.property_id,
  //sortComparer: (a,b) => a.code.localeCompare(b.code),
});

export const property_mediaSlice = createSlice({
  name: "property_media",
  initialState: property_mediaAdapter.getInitialState(),
  reducers: {
    add: property_mediaAdapter.addOne,
    setAll: property_mediaAdapter.setAll,
    update: property_mediaAdapter.upsertOne,
    removeOne: property_mediaAdapter.removeOne,
    empty: property_mediaAdapter.removeAll,
    updatePropertyJson(state, action){
        const {property_id, key, value} = action.payload;
        const element = JSON.parse(JSON.stringify(state.entities));
        const ppty = element[property_id];
        if (ppty) {
            let keys = key.split('__')
            let setter_key = `${keys[0]}`
            if(keys.length > 1){
                keys?.slice(1, keys.length).map((k)=>{
                    setter_key = setter_key + `.${k}`
                })
            }
            const custom_value = set(ppty, setter_key, value);
            property_mediaAdapter.upsertOne(state, custom_value);
            return;
        }
    }
},
});

export const { actions } = property_mediaSlice;

export const selectors = property_mediaAdapter.getSelectors((state) => state.property_media);