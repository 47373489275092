import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button as ButtonPrime } from "primereact/button";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ExplosionList = (props) => {
  const { data, setData, dimensions, masterId, devCode } = props;
  const [ explosion, setExplosion ] = useState([]);
  const [ habilitado, setHabilitado] = useState(true);
  const [ selected, setSelected ] = useState(null);

  useEffect(() => {
    setExplosion(data);
  }, [data]);

  /*
  useEffect(() => {
    if (explosion) {
      setData(explosion);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explosion]);
  */

  const textEditor = (options) => {
    return <InputText 
      type="text" 
      value={options.value} 
      onChange={(e) => options.editorCallback(e.target.value)} 
    />;
  }

  const onRowEditComplete = (e) => {
    let _explosion = [...explosion];
    let { newData, index } = e;
    const _newData = {};
    dimensions.forEach(item => {
      _newData[item.name] = newData[item.name];
    });
    _explosion[index] = _newData;
    setData(masterId, _explosion);
  }

  const cartesian =
  (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

  const onExplode = () => {
    // explode items based on dimensions 

    // paso 1 tomar todos los items de las dimensiones
    // [[{"TORRE":"WEST"}, {"TORRE": "EAST"}], [{"PISO":"1"}, {"PISO": "2"}], [{"DEPTO":"1"}, {"DEPTO": "2"}]]
    const dims = [];
    
    dimensions.forEach(dim => {
      const elem = [];
      dim.items.forEach(item => {
        const tupla = {};
        tupla[dim.name] = item;
        elem.push(tupla);
      });
      dims.push(JSON.parse(JSON.stringify(elem)));
    });

    // paso 2 combinar todos los items de las dimensiones
    // [ { "TORRE": "WEST", "PISO": 1", "DEPTO": "1"}, { "TORRE": "WEST", "PISO": 1", "DEPTO": "2"}]
    const exp = cartesian(...dims);
    const cart = exp.map((elem) => {
      const item = {};
      let code = `${devCode}`;
      for (let i=0;i<elem.length;i++) {
        item[Object.keys(elem[i])[0]] = Object.values(elem[i])[0];
        code = `${code}-${Object.values(elem[i])[0]}`
        item.id = uuidv4();
      }
      return {...item, code};
    });

    // paso 3, si alguna dimensión tiene un prefijo, voy a alterar el valor final para agregar el prefijo.
    dimensions.forEach(dim => {
      if (dim.pre) {
        console.log('pre: ', dim.pre);
        for (let i = 0; i < cart.length; i++) {
          cart[i][dim.name] = `${cart[i][dim.pre]}${cart[i][dim.name]}`;
          const newCode = cart[i].code.split('-').slice(0, dimensions.length).join('-').concat('-', cart[i][dim.name]);
          //console.log('newCode: ', newCode);
          cart[i]['code'] = newCode;
        }
      }
    });
    
    setData( masterId, cart);
    setHabilitado(false);
  }

  const deleteRow = (rowData) => {
    //console.log(rowData);
    //console.log(data);
    const deletedData = explosion.filter(item => { return (item.id !== rowData.id) });
    //setData(masterId, deletedData );
    setExplosion(deletedData);
  }
  
  const actionsBodyTemplate = (rowData, props ) => {
    const rowEditor = props.rowEditor;
    if ( rowEditor.editing ) {
      return rowEditor.element;
    } else {
      return (
        <>
          <ButtonPrime onClick={rowEditor.onInitClick} className={rowEditor.initClassName} key={`edit-${rowData.id}`}>
            <span className='p-row-editor-init-icon pi pi-fw pi-pencil p-clickable'></span>
          </ButtonPrime>
          <ButtonPrime onClick={() => deleteRow(rowData)} className={rowEditor.initClassName} key={`delete-${rowData.id}`}>
            <span className='p-row-editor-init-icon pi pi-fw pi-trash p-clickable'></span>
          </ButtonPrime>
        </>
      );
    }
  }

  return (
    <Flex direction="column">
      <Flex direction={{ md: "row", base: "column" }} w="full" alignItems="end">
        <Button
          mb="6"
          rightIcon={<AddIcon />}
          colorScheme="teal"
          disabled={!habilitado}
          onClick={onExplode}
          ml="auto">
          Explosionar
        </Button>
      </Flex>
      <DataTable
        scrollable scrollHeight="400px"
        id={`tableExp${masterId}`}
        value={explosion} 
        editMode="row"
        dataKey="id" 
        onRowEditComplete={onRowEditComplete} 
        responsiveLayout="scroll" 
        selection={selected}
        onSelectionChange={(e) => setSelected(e.value)}
      >
        {dimensions.map((dim) => (
          <Column
          sortable
          field={dim["name"]}
          header={dim["name"]}
          editor={(options) => textEditor(options)} 
          style={{ width: `${90/dimensions.length}%`}}
          />
        ))}
        <Column 
          rowEditor 
          body={actionsBodyTemplate}
          header="Acciones" 
          headerStyle={{ width: '10%', minWidth: '8rem' }} 
          bodyStyle={{ textAlign: 'center' }}
        />
      </DataTable>
    </Flex>
  );

}

export default ExplosionList;